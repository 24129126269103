/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Upload } from 'tus-js-client'
import { useHistory, useLocation, Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import ChipInput from 'material-ui-chip-input'

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { CloudUpload } from '@material-ui/icons'
import jwtDecode from 'jwt-decode'
import Yamde from 'yamde'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ArticleService from '../services/ArticleService'
import VideoUploadedDialog from '../components/VideoUploadedDialog'
import AdminPanelContext from '../context/AdminPanelContext'
import UserService from '../services/UserService'
import Validator from '../helpers/Validations'

const headerPost = {
  Accept: 'application/vnd.vimeo.*+json;version=3.4',
  Authorization: `bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
}

const ArticleUpload = () => {
  const [open, setOpen] = useState(false)
  const [progress, setProgress] = useState(0)
  const [title, setTitle] = useState('')
  const [category, setCategory] = useState('')
  const [shortDesc, setShortDesc] = useState('')
  const [content, setContent] = useState('')
  const [keywords, setKeywords] = useState([])
  const [videoUrl, setVideoUrl] = useState()
  const [editVideo, setEditVideo] = useState(false)
  const [terms, setTermsCheck] = useState(false)
  const [modal, setModal] = useState(false)
  const context = React.useContext(AdminPanelContext)
  const [categories, setCategories] = useState([])
  const [videoValidLength, setVideoValidLength] = useState(true)
  const [startedUpload, setStartedUpload] = useState(false)
  const [openExampleArticle, setOpenExampleArticle] = useState(false)
  const [error, setError] = useState({})

  const history = useHistory()
  const location = useLocation()

  useEffect(async () => {
    getCategories()

    if (location.pathname.split('/')[2] === 'revise') {
      const articleId = location.pathname.split('/')[3]
      await ArticleService.getArticle(articleId).then((articleData) => {
        context.setEditedArticle(articleData)
        context.setToEditArticle(true)
        setTitle(articleData.title)
        setCategory(articleData.categoryId)
        setShortDesc(articleData.short_description)
        setVideoUrl(articleData.video_url)
        setContent(articleData.content)
        setKeywords(articleData.keywords)
      })
    }

    if (context.toEditArticle) {
      setTitle(context.editedArticle.title)
      setCategory(context.editedArticle.categoryId)
      setShortDesc(context.editedArticle.short_description)
      setVideoUrl(context.editedArticle.video_url)
      setContent(context.editedArticle.content)
      setKeywords(context.editedArticle.keywords)
    }
  }, [])

  const handleTitleChange = (event) => {
    if (!Validator.titleValidate(event.target.value)) {
      setError({
        ...error,
        title: 'Title must be between 3 and 100 characters!',
      })
    } else {
      setError({
        ...error,
        title: '',
      })
    }
    setTitle(event.target.value)
  }
  const handleCategoryChange = (event) => {
    setCategory(event.target.value)
  }
  const handleShortDescChange = (event) => {
    if (!Validator.descriptionValidate(event.target.value)) {
      setError({
        ...error,
        description: 'Maximum number of characters allowed is 255!',
      })
    } else {
      setError({
        ...error,
        description: '',
      })
    }
    setShortDesc(event.target.value)
  }

  const onVideoChange = (event) => {
    setVideoUrl(event.target.files[0])

    const video = document.createElement('video')
    video.preload = 'metadata'
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      if (video.duration > 420) setVideoValidLength(false)
    }
    video.src = URL.createObjectURL(event.target.files[0])

    if (context.toEditArticle) setEditVideo(true)
  }

  const uploadVideo = async () => {
    setStartedUpload(true)
    if (!context.toEditArticle || editVideo) {
      const file = videoUrl
      const fileSize = file.size.toString()

      const response = await axios({
        method: 'post',
        url: `https://api.vimeo.com/me/videos`,
        headers: headerPost,
        data: {
          upload: {
            approach: 'tus',
            size: fileSize,
          },
          name: title,
        },
      })

      const id = response.data.link.split('/')[3]
      const decodedID = jwtDecode(localStorage.getItem('token'))

      const upload = new Upload(file, {
        endPoint: 'https://api.vimeo.com/me/videos',
        uploadUrl: response.data.upload.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        headers: {},
        onError() {},
        onProgress(bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          setProgress(percentage)
        },
        onSuccess() {
          setModal(true)
          setVideoUrl(response.data.link.split('/')[3])
        },
      })
      if (!context.toEditArticle) {
        ArticleService.addArticle({
          video_url: id,
          short_description: shortDesc,
          title,
          categoryId: category,
          userId: decodedID.user.id,
          date: Date.now(),
          content,
          keywords,
        })
          .then(async () => {
            if (localStorage.getItem('token') === null) {
              history.push('/login')
            } else {
              const user = await UserService.getSingleUser(decodedID.user.id)
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  from: 'selman@procerasoft.com',
                  to: user.email,
                  name: `${user.first_name} ${user.last_name}`,
                  title,
                }),
              }
              await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/email/video-uploaded`,
                requestOptions,
              )
            }
          })
          .catch(() => {})
      } else {
        ArticleService.editArticle({
          id: context.editedArticle.id,
          video_url: id,
          short_description: shortDesc,
          title,
          content,
          categoryId: category,
          keywords,
        })
          .then(() => {
            if (localStorage.getItem('token') === null) {
              history.push('/login')
            } else {
              setEditVideo(false)
              context.setToEditArticle(false)
              // context.setAdminPage(1)
              if (location.pathname.split('/')[2] === 'revise')
                history.push('/')
              history.push('/admin/articles')
            }
          })
          .catch(() => {})
      }

      // Start the upload
      upload.start()
    } else {
      ArticleService.editArticle({
        id: context.editedArticle.id,
        short_description: shortDesc,
        title,
        content,
        categoryId: category,
        keywords,
      })
        .then(() => {
          if (localStorage.getItem('token') === null) {
            history.push('/login')
          } else {
            setEditVideo(false)
            context.setToEditArticle(false)
            if (location.pathname.split('/')[2] === 'revise') history.push('/')
            history.push('/admin/articles')
          }
        })
        .catch(() => {})
    }
  }

  const handleCheck = (event) => {
    setTermsCheck(event.target.checked)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const getCategories = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data)
      })
  }

  return (
    <>
      {!context.toEditArticle && <Navbar />}
      <Container>
        <Typography variant="h5">Upload new article</Typography>
        <form className="root">
          <TextField
            id="standard-basic"
            value={title}
            label="Title"
            onChange={handleTitleChange}
            color="secondary"
            required
            error={error.title}
            helperText={error.title}
          />
          <FormControl className="select">
            <InputLabel id="demo-controlled-open-select-label" required>
              Category
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={category}
              onChange={handleCategoryChange}
              color="secondary"
              required
              error={error.category}
              helperText={error.category}
            >
              {categories &&
                categories.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            id="standard-multiline-flexible"
            label="Short Description"
            multiline
            rowsMax={5}
            onChange={handleShortDescChange}
            value={shortDesc}
            color="secondary"
            error={error.description}
            helperText={error.description}
          />
          <ChipInput
            label="Keywords"
            value={keywords}
            placeholder="Press enter to add keyword"
            onAdd={(chip) => {
              setKeywords([...keywords, chip.toLowerCase()])
              if (!Validator.keywordsValidate(keywords.length + 1)) {
                setError({
                  ...error,
                  keywords: 'Add at least 5 keywords!',
                })
              } else {
                setError({
                  ...error,
                  keywords: '',
                })
              }
            }}
            onDelete={(keyword) => {
              setKeywords(keywords.filter((item) => item !== keyword))
              if (!Validator.keywordsValidate(keywords.length - 1)) {
                setError({
                  ...error,
                  keywords: 'Add at least 5 keywords!',
                })
              } else {
                setError({
                  ...error,
                  keywords: '',
                })
              }
            }}
            required
            error={error.keywords}
            helperText={error.keywords}
          />
        </form>
        <h3
          style={{
            fontWeight: '400',
            alignSelf: 'start',
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '1rem',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          }}
        >
          Article content: (
          <Span onClick={() => setOpenExampleArticle(true)}>
            Article Guidelines / Example Article
          </Span>
          )
        </h3>
        <Yamde
          value={content}
          handler={setContent}
          toolbar={[
            'bold',
            'italic',
            'heading1',
            'heading2',
            'heading3',
            'ulist',
            'olist',
          ]}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={terms}
              onChange={handleCheck}
              name="TermsAndConditions"
              color="secondary"
            />
          }
          label={
            <div>
              <span>I accept the </span>
              <Link to="/terms-and-conditions" style={{ color: '#55bbf1' }}>
                Terms and Conditions
              </Link>
            </div>
          }
        />
        <input
          className="file"
          accept="video/mp4,video/x-m4v,video/*"
          id="video-button-file"
          type="file"
          onChange={onVideoChange}
          required
        />
        {videoValidLength ? (
          <p>Note: Maximum video length is 7 minutes!</p>
        ) : (
          <h4 style={{ color: 'red' }}>
            Video length is over 7 minutes, please upload a shorter video!
          </h4>
        )}
        <label htmlFor="video-button-file">
          <Button
            variant="contained"
            color="secondary"
            component="span"
            startIcon={<CloudUpload />}
            className="button"
          >
            Upload video
          </Button>
        </label>
        <h4 style={{ marginBottom: '10px' }}>{videoUrl && videoUrl.name}</h4>
        {startedUpload && (
          <Typography variant="h8">
            Your video is being uploaded.Thank you for your patience.
          </Typography>
        )}
        {progress > 0 && (
          <div>
            <LinearProgress
              variant="determinate"
              style={{
                marginTop: '30px',
                marginBottom: '30px',
                width: '300px',
                height: '5px',
                color: 'blue',
                zIndex: '20',
                backgroundColor: '#000',
              }}
              value={progress}
              color="secondary"
            />
          </div>
        )}
        <Button
          variant="contained"
          color="secondary"
          component="span"
          className="submit"
          onClick={uploadVideo}
          disabled={
            !terms ||
            !videoValidLength ||
            !videoUrl ||
            !category ||
            error.title ||
            error.keywords ||
            error.description
          }
          style={{ marginTop: '20px' }}
        >
          Submit
        </Button>
        <Typography variant="body2" style={{ marginTop: '20px' }}>
          All * fields required
        </Typography>
        <VideoUploadedDialog open={modal} setOpen={() => setModal(false)} />

        <Dialog
          open={openExampleArticle}
          onClose={() => setOpenExampleArticle(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Article guidelines</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div>
                <p>
                  <strong>Abstract&nbsp;</strong>
                </p>
                <p>
                  <strong>Background: </strong>
                  <span style={{ 'font-weight': '400' }}>
                    In one or two sentences, summarize the scientific body of
                    knowledge surrounding your study and how this led to your
                    investigation.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Indications: </strong>
                  <span style={{ 'font-weight': '400' }}>
                    State the reason(s) behind the intervention shown.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Technique Description: </strong>
                  <span style={{ 'font-weight': '400' }}>
                    Provide a concise overview of the surgical or interventional
                    technique shown, as applicable.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Results: </strong>
                  <span style={{ 'font-weight': '400' }}>
                    Briefly summarize the expected outcome of the procedure
                    being described based&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ 'font-weight': '400' }}>
                    upon the authors’ experience and/or salient
                    literature.&nbsp;
                  </span>
                </p>
                <p>
                  <strong>Discussion/Conclusion</strong>
                  <span style={{ 'font-weight': '400' }}>: </span>
                  <span style={{ 'font-weight': '400' }}>
                    Summarize the most important conclusions or recommendations
                    that can be directly drawn from your study / technique /
                    innovation.
                  </span>
                </p>
                <p>
                  <strong>Keywords: </strong>
                  <span style={{ 'font-weight': '400' }}>
                    Include at least 5 keywords for indexing.
                  </span>
                </p>
                <p>
                  <strong>Video transcript</strong>
                  <span style={{ 'font-weight': '400' }}>&nbsp;</span>
                </p>
                <p>
                  <span style={{ 'font-weight': '400' }}>
                    Transcribe the narration verbatim, and do not add or exclude
                    any text. Use a paragraph format to indicated topic and
                    slide transitions. Do not use any headings or subheadings in
                    this section.
                  </span>
                  <em>
                    <span style={{ 'font-weight': '400' }}>
                      If you must add text that is not in the video narration,
                      italicize it.&nbsp;
                    </span>
                  </em>
                </p>
                <p>
                  <strong>References:&nbsp;</strong>
                </p>
                <p>
                  <span style={{ 'font-weight': '400' }}>
                    A reference section must appear after your transcript in AMA
                    Style.&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ 'font-weight': '400' }}>
                    References do not have to be cited within the video
                    transcript text but rather can include content related to
                    the technique depicted or provide outcomes for the procedure
                    highlighted.&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ 'font-weight': '400' }}>
                    References should be double-spaced in alphabetical order by
                    the last name of the first author and numbered according to
                    the alphabetical listing.&nbsp;
                  </span>
                </p>
                <p>
                  <span style={{ 'font-weight': '400' }}>
                    References should be limited to 5.&nbsp;
                  </span>
                </p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenExampleArticle(false)}
              color="default"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Footer />
    </>
  )
}

const Span = styled.span`
  color: #55bbf1;
  cursor: pointer;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-height: 280px;
  justify-self: center;
  align-items: center;
  background-color: transparent;
  box-shadow: 1px 7px 17px lightgray;
  padding: 30px;
  border-radius: 15px;
  margin: 30px auto 50px;

  .root {
    display: flex;
    flex-direction: column;
  }

  .button {
    background-color: #55bbf1;
    color: white;
    max-width: 350px;
    margin: 20px 5px;
  }

  .video {
    font: 1rem/1.5 'PT Sans', Arial, sans-serif;
    background: #55bbf1;
  }

  .submit {
    background: #55bbf1;
  }

  .file {
    margin: 10px auto;
    width: auto;
  }

  input[type='file'] {
    display: none;
  }

  #standard-basic {
    width: 480px;
  }

  .MuiFormControl-root {
    width: calc(100% - 60px);
    margin: auto;
    margin-bottom: 20px;
  }

  #demo-controlled-open-select {
    width: 480px;
  }

  @media only screen and (max-width: 550px) {
    padding: 10px;
    width: 90%;
    #demo-controlled-open-select {
      width: 90%;
    }

    #standard-basic {
      width: 90vw;
    }
  }
`

export default ArticleUpload
