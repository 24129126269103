import { NotificationManager } from 'react-notifications'

class UserService {
  static BACKEND_URL = process.env.REACT_APP_BACKEND_URL

  static async getUsers() {
    return fetch(`${this.BACKEND_URL}/api/users`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((res) => res.json())
  }

  static async createTokenForReviewerAccept(id) {
    return fetch(`${this.BACKEND_URL}/api/users/changeRole`, {
      method: 'POST',
      headers: {
        mode: 'cors',

        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
    })
      .then((res) => res.json())
      .then((res) => {
        localStorage.setItem('token', res.token)
      })
  }

  static async getSingleUser(id) {
    return fetch(`${this.BACKEND_URL}/api/users/${id}`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((res) => res.json())
  }

  static async deleteUser(data) {
    return fetch(`${this.BACKEND_URL}/api/users/${data}`, {
      method: 'DELETE',
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((response) => {
      response.json()
      if (response.status === 200) {
        NotificationManager.success('User Deleted Successfully')
      } else if (response.status === 401) {
        try {
          localStorage.removeItem('token')
          NotificationManager.error('Please authorize!')
        } catch (error) {
          NotificationManager.error(error)
        }
      } else if (response.status === 405) {
        NotificationManager.error('Can not delete super admin!')
      }
    })
  }

  static async addUser(data) {
    return fetch(`${this.BACKEND_URL}/api/users`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json()
      if (response.status === 401) {
        try {
          localStorage.removeItem('token')
          NotificationManager.error('Please authorise!')
        } catch (error) {
          NotificationManager.error(error)
        }
      }
    })
  }

  static async editUser(data) {
    return fetch(`${this.BACKEND_URL}/api/users/${data.id}`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json()
      if (response.status === 401) {
        try {
          localStorage.removeItem('token')
          NotificationManager.error('Please authorise!')
        } catch (error) {
          NotificationManager.error(error)
        }
      }
    })
  }

  static async editUserPassword(data, id) {
    return fetch(`${this.BACKEND_URL}/api/users/reset-password/${id}`, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json()
    })
  }
}

export default UserService
