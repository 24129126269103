import React, { useEffect, useRef } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import LatestVideos from '../components/LatestVideos'
import Navbar from '../components/Navbar'
import ChiefEditors from '../components/ChiefEditors'
import AboutFoundingEditors from '../components/AboutFoundingEditors'

const HomePage = (props) => {
  const ourMission = useRef(null)
  const scrollToSection = () => {
    if (
      props.location.state &&
      props.location.state.subsection === 'ourMission'
    ) {
      ourMission.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (props.location.state) scrollToSection()
  }, [props.location.state])
  return (
    <div>
      <Navbar />
      <Header />
      <ChiefEditors />
      <div ref={ourMission}>
        <AboutFoundingEditors />
      </div>
      <LatestVideos />
      <Footer />
    </div>
  )
}
export default HomePage
