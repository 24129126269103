import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import EmailIcon from '@material-ui/icons/Email'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import StarIcon from '@material-ui/icons/Star'
import { NotificationManager } from 'react-notifications'
import UserService from '../services/UserService'
import AdminPanelContext from '../context/AdminPanelContext'
import AuthService from '../services/AuthService'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const Row = (props) => {
  let currentUser = null
  if (localStorage.getItem('token')) {
    currentUser = jwtDecode(localStorage.getItem('token'))
  }
  const classes = useRowStyles()
  const [user] = useState(props.user)
  const [open, setOpen] = React.useState(false)
  const context = React.useContext(AdminPanelContext)
  const history = useHistory()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    if (user.id !== 1) {
      await UserService.deleteUser(user.id)
    }

    props.setRefresh(!props.refresh)
  }

  const handleEdit = async () => {
    context.setUserID(user.id)
    context.setActionEdit(1)
    // context.setAdminPage(4)
    history.push('/admin/user')
  }

  const userRole = (role) => {
    if (role === 0) {
      return 'user'
    }
    if (role === 1) {
      return 'admin'
    }
    if (role === 2) {
      return 'reviewer'
    }
    return ''
  }

  const handleAddReviewer = async () => {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/invite`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.id }),
    }).then(async () => {
      const data = {
        from: 'selman@procerasoft.com',
        to: user.email,
        name: `${user.first_name} ${user.last_name}`,
        id: user.id,
      }
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/email`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((res) => {
        NotificationManager.success('E-mail invite was sent!')
        res.json()
      })
    })
  }

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This user will be deleted, are you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="default">
              Close
            </Button>
            <Button
              onClick={handleDelete}
              style={{ color: '#FF0000' }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TableRow className={classes.root}>
        <TableCell>{user.id}</TableCell>
        <TableCell component="th" scope="row">
          {user.first_name}
        </TableCell>
        <TableCell>{user.last_name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{userRole(user.role)}</TableCell>
        <TableCell>{user.date}</TableCell>
        <TableCell align="left">
          {currentUser && currentUser.user && currentUser.user.id === 1 ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEdit}
              startIcon={<EditIcon />}
              style={{ margin: '5px' }}
            >
              Edit
            </Button>
          ) : null}
          {user.id !== 1 &&
          currentUser &&
          currentUser.user &&
          currentUser.user.id !== 1 ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEdit}
              startIcon={<EditIcon />}
              style={{ margin: '5px' }}
            >
              Edit
            </Button>
          ) : null}
          {user.id !== 1 && (
            <Button
              variant="contained"
              style={{ margin: '5px', color: '#FF0000' }}
              startIcon={<DeleteIcon />}
              onClick={handleClickOpen}
            >
              Delete
            </Button>
          )}
          {user.role !== 1 && user.role !== 2 ? (
            <Button
              variant="contained"
              color="default"
              onClick={handleAddReviewer}
              startIcon={<StarIcon />}
              style={{ margin: '5px' }}
            >
              Add Reviewer
            </Button>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  )
}

const UsersTable = () => {
  const [users, setUsers] = useState([])
  const [refresh, setRefresh] = useState(true)
  const context = React.useContext(AdminPanelContext)

  const history = useHistory()

  const getUsers = async () => {
    const data = await UserService.getUsers()
    setUsers(data)
  }

  useEffect(async () => {
    if (localStorage.getItem('token') != null) {
      const token = localStorage.getItem('token')
      AuthService.verify(token).then((err) => {
        if (err === false) {
          localStorage.removeItem('token')
          history.push('/login')
        }
      })
    }
    getUsers()
  }, [refresh])

  if (localStorage.getItem('token') === null) {
    history.push('/login')
  }

  return (
    <Wrap>
      <Link to="/admin/user" style={{ textDecoration: 'none' }}>
        <Button
          onClick={() => {
            // context.setAdminPage(4)
            context.setActionEdit(0)
          }}
          variant="contained"
          color="secondary"
          style={{ margin: '0 5px 10px 5px' }}
          startIcon={<PersonAddIcon />}
        >
          Add user
        </Button>
      </Link>
      <Button
        onClick={() => {
          history.push('/invite-user')
        }}
        variant="contained"
        color="default"
        style={{ margin: '0 5px 10px 5px' }}
        startIcon={<EmailIcon />}
      >
        Invite user via email
      </Button>
      <Button
        onClick={() => {
          history.push('/invite-reviewer')
        }}
        variant="contained"
        color="default"
        style={{ margin: '0 5px 10px 5px' }}
        startIcon={<EmailIcon />}
      >
        Invite reviewer via email
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.map((user) => (
                <Row
                  setRefresh={setRefresh}
                  refresh={refresh}
                  key={user.id}
                  user={user}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrap>
  )
}

export default UsersTable

const Wrap = styled.div`
  width: 80%;
  margin: 0 auto 2em auto;
`
