import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import styled from 'styled-components'
import SearchIcon from '@material-ui/icons/Search'
import { InputBase, makeStyles } from '@material-ui/core'
import Logo from '../assets/images/Logo.png'
import hamburger from '../assets/images/hamburger.png'
import useResize from '../useResize'
import AdminPanelContext from '../context/AdminPanelContext'

const Navbar = () => {
  const context = React.useContext(AdminPanelContext)
  const [mobile, setMobile] = useState()
  const [displayNav, setDisplayNav] = useState(false)
  const [nav1, setNav1] = useState(false)
  const [nav2, setNav2] = useState(false)
  const [nav3, setNav3] = useState(false)
  const [txt, setTxt] = useState(
    localStorage.getItem('token') ? 'Log Out' : 'Sign Up',
  )
  const [displayProp, setDisplayProp] = useState(
    localStorage.getItem('token') ? 'none' : '',
  )
  const getUserRole = () => {
    if (localStorage.getItem('token')) {
      const decodedID = jwtDecode(localStorage.getItem('token'))
      return decodedID.user.role
    }
    return null
  }
  const searchBar = useRef(null)

  const focusSearchBar = () => {
    searchBar.current.focus()
  }

  const handleOnSearchChange = (event) => {
    context.setFilters({ ...context.filters, search: event.target.value })
  }

  const onClickSearchBarRedirect = () => {
    if (window.location.pathname.toString() !== '/articles')
      history.push('/articles')
  }

  const classes = useStyles()
  const size = useResize()
  const history = useHistory()
  const [action] = useState(localStorage.getItem('token') ? 1 : 0)

  const LogOut = () => {
    localStorage.removeItem('token')
    setTxt('Sign Up')
    setDisplayProp('')
    history.push('/')
  }

  useEffect(
    () => {
      setMobile(size[0] <= 768)
      if (window.location.pathname.toString() === '/articles') focusSearchBar()
    },
    [size],
    [txt],
  )

  return (
    <>
      <Wrapper>
        <NavLeft>
          <Link to="/">
            <img className="logo" src={Logo} alt="" />
          </Link>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleOnSearchChange}
              onClick={onClickSearchBarRedirect}
              inputRef={searchBar}
            />
          </div>

          {mobile && (
            <DropdownIcon
              src={hamburger}
              alt=""
              onClick={() => setDisplayNav(!displayNav)}
            />
          )}
        </NavLeft>

        {!mobile && (
          <NavRight>
            <Ul>
              <Link className="link" to="/">
                <Dropdown>
                  <li>Journal Home</li>
                  <DropdownItem>
                    <Ul
                      style={{
                        flexDirection: 'column',
                        backgroundColor: 'white',
                      }}
                    >
                      <Link
                        className="link"
                        to={{
                          pathname: '/',
                          state: { subsection: 'ourMission' },
                        }}
                      >
                        <li>About This Journal</li>
                      </Link>
                      <Link className="link" to="/editorial-board">
                        <li>Editorial Board</li>
                      </Link>
                    </Ul>
                  </DropdownItem>
                </Dropdown>
              </Link>
              <Link className="link" to="/articles">
                <Dropdown>
                  <li>Browse Journal</li>
                  <DropdownItem>
                    <Ul
                      style={{
                        flexDirection: 'column',
                        backgroundColor: 'white',
                      }}
                    >
                      <Link className="link" to="/articles/categories">
                        <li>Browse by Category</li>
                      </Link>
                      <Link
                        className="link"
                        to={{
                          pathname: '/articles/categories',
                          state: { browseByYear: true },
                        }}
                      >
                        <li>Browse by Year</li>
                      </Link>
                    </Ul>
                  </DropdownItem>
                </Dropdown>
              </Link>
              <Link className="link" to="/about">
                <Dropdown>
                  <li>Journal Info</li>
                  <DropdownItem>
                    <Ul
                      style={{
                        flexDirection: 'column',
                        backgroundColor: 'white',
                      }}
                    >
                      <Link className="link" to="/about">
                        <li>Journal Description</li>
                      </Link>
                      <Link
                        className="link"
                        to={{
                          pathname: '/about',
                          state: { subsection: 'aims' },
                        }}
                      >
                        <li>Aims and Scope</li>
                      </Link>
                      <Link
                        className="link"
                        to={{
                          pathname: '/about',
                          state: { subsection: 'submission' },
                        }}
                      >
                        <li>Submission Guidelines</li>
                      </Link>
                      <Link
                        className="link"
                        to={{
                          pathname: '/about',
                          state: { subsection: 'permissions' },
                        }}
                      >
                        <li>Permissions</li>
                      </Link>
                    </Ul>
                  </DropdownItem>
                </Dropdown>
              </Link>
              {getUserRole() === 1 && (
                <Link className="link" to="/admin/users">
                  <li>Admin Panel</li>
                </Link>
              )}
              {getUserRole() === 2 && (
                <Link className="link" to="/reviewer/articles">
                  <li>Reviewer Panel</li>
                </Link>
              )}
            </Ul>
            <Buttons>
              <Link to="/login">
                <Button1 style={{ display: displayProp }}>Log In</Button1>
              </Link>
              <Link to={action ? '/' : '/register'}>
                <Button2 onClick={action ? LogOut : null}>{txt}</Button2>
              </Link>
            </Buttons>
          </NavRight>
        )}
      </Wrapper>
      {displayNav && (
        <MobileNavWrapper>
          <div className="main-nav-link">
            <div>
              <Link to="/">Journal Home</Link>
              <Arrow
                onClick={() => {
                  setNav1(!nav1)
                  setNav2(false)
                  setNav3(false)
                }}
              />
            </div>
            <div className={nav1 ? 'sub-navl-shown' : 'sub-navl-hidden'}>
              <Link to="/about">About This Journal</Link>
              <Link to="/editorial-board">Editorial Board</Link>
            </div>
          </div>
          <div className="main-nav-link">
            <div>
              <Link to="/articles">Browse Journal</Link>
              <Arrow
                onClick={() => {
                  setNav1(false)
                  setNav2(!nav2)
                  setNav3(false)
                }}
              />
            </div>
            <div className={nav2 ? 'sub-navl-shown' : 'sub-navl-hidden'}>
              <Link to="/articles/categories">Browse By Category</Link>
              <Link
                to={{
                  pathname: '/articles/categories',
                  state: { browseByYear: true },
                }}
              >
                Browse By Year
              </Link>
            </div>
          </div>
          <div className="main-nav-link">
            <div>
              <Link to="/about">Journal Description</Link>
              <Arrow
                onClick={() => {
                  setNav1(false)
                  setNav2(false)
                  setNav3(!nav3)
                }}
              />
            </div>
            <div className={nav3 ? 'sub-navl-shown' : 'sub-navl-hidden'}>
              <Link
                to={{
                  pathname: '/about',
                  state: { subsection: 'aims' },
                }}
              >
                Aims And Scope
              </Link>
              <Link
                to={{
                  pathname: '/about',
                  state: { subsection: 'submission' },
                }}
              >
                Submission Guidelines
              </Link>
              <Link
                to={{
                  pathname: '/about',
                  state: { subsection: 'permissions' },
                }}
              >
                Permissions
              </Link>
            </div>
          </div>
          {getUserRole() === 1 && (
            <div className="main-nav-link">
              <div>
                <Link to="/admin">Admin Panel</Link>
              </div>
            </div>
          )}
          {getUserRole() === 2 && (
            <div className="main-nav-link">
              <div>
                <Link to="/reviewer">Reviewer Panel</Link>
              </div>
            </div>
          )}

          <Buttons>
            <Link style={{ display: displayProp }} to={action ? '/' : '/login'}>
              <Button1>Log in</Button1>
            </Link>
            <Link to={action ? '/' : '/register'}>
              <Button2 onClick={action ? LogOut : null}>{txt}</Button2>
            </Link>
          </Buttons>
        </MobileNavWrapper>
      )}
    </>
  )
}

export default Navbar

const MobileNavWrapper = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0;

  .main-nav-link {
    div {
      width: 95vw;
      display: flex;
      justify-content: space-between;
    }

    a {
      text-decoration: none;
      color: black;
      margin-right: 10px;
      padding: 8px;
      margin: 3px;
    }

    .sub-navl-hidden {
      margin-top: 10px;
      height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }

    .sub-navl-shown {
      margin-top: 10px;
      height: unset;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      background-color: #f3f3f3;
    }
  }
`

const Wrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  box-sizing: border-box;
  width: 100%;
  font-weight: 600;
  overflow: hidden;
  height: 84px;
  display: flex;
  align-items: center;
  padding: 0 50px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 20px lightgray;

  .logo {
    padding-top: 5px;
    width: 120px;
  }

  @media only screen and (max-width: 992px) {
    padding: 0 2px 0 15px;
  }
  @media only screen and (max-width: 768px) {
    .logo {
      padding-top: 5px;
      width: 70px;
      margin-right: 10px;
    }

    justify-content: space-between;
    padding: 0 10px 0 10px;
  }
`
const NavLeft = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
  @media only screen and (max-width: 1400px) {
    width: 40%;
  }
  @media only screen and (max-width: 992px) {
    width: 45%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`
const NavRight = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 1400px) {
    width: 60%;
  }
  @media only screen and (max-width: 992px) {
    width: 55%;
  }
`

const Ul = styled.ul`
  z-index: 10;
  width: 100%;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid transparent;
  transition: all 0.1s ease;

  li {
    text-align: center;
    padding: 7px 10px;
    font-size: 1em;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: ease-in-out 0.2s;

    &:hover {
      border-bottom: 2px solid #55bbf1;
    }
  }

  @media only screen and (max-width: 992px) {
    width: 66%;
  }

  .link {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
`
const DropdownItem = styled.div`
  position: absolute;
  display: none !important;
  top: -30px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 84px;

  li {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
  }

  &:hover {
    display: flex !important;
  }
`

const Dropdown = styled.div`
  ${Ul} {
    border-radius: 5px;
  }

  &:hover ${DropdownItem} {
    display: flex !important;
  }
`
const Buttons = styled.div`
  display: flex;
  min-width: 80px !important;
`
const Button1 = styled.button`
  width: 80px;
  text-align: center;
  color: #55bbf1;
  background-color: #f4f4f4;
  border: 2px solid #f4f4f4;
  border-radius: 5px;
  padding: 8px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.1s ease-out;

  &:hover {
    border: 2px solid #55bbf1;
    background-color: #55bbf1;
    color: white;
  }
`
const Button2 = styled.button`
  width: 80px;
  text-align: center;
  color: white;
  background-color: #55bbf1;
  border: 2px solid #55bbf1;
  border-radius: 5px;
  padding: 8px;
  margin: 0 7px;
  cursor: pointer;
  display: ${(props) => props.display};
  transition: all 0.1s ease-out;

  &:hover {
    border: 2px solid #55bbf1;
    background-color: white;
    color: #55bbf1;
  }
`

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
`

const Arrow = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 0;
  margin: 15px;
  line-height: 100%;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #242424;
`

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#00000010',
    '&:hover': {
      backgroundColor: '#00000015',
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))
