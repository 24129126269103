import React from 'react'
import Markdown from 'markdown-to-jsx'
import Typography from '@material-ui/core/Typography'

const ArticleMarkdown = ({ children }) => (
  <Markdown
    options={{
      overrides: {
        h1: {
          component: Typography,
          props: {
            variant: 'h3',
          },
        },
        h2: {
          component: Typography,
          props: {
            variant: 'h4',
          },
        },
        h3: {
          component: Typography,
          props: {
            variant: 'h5',
          },
        },
        h4: {
          component: Typography,
          props: {
            variant: 'h6',
          },
        },
        h5: {
          component: Typography,
          props: {
            variant: 'subtitle1',
          },
        },
        h6: {
          component: Typography,
          props: {
            variant: 'subtitle2',
          },
        },
        p: {
          component: Typography,
          props: {
            variant: 'body1',
            align: 'justify',
          },
        },
      },
    }}
  >
    {children}
  </Markdown>
)

export default ArticleMarkdown
