import React from 'react'
import styled from 'styled-components'

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

const SocialShare = () => {
  const shareUrl = window.location.href
  return (
    <Social>
      <FacebookShareButton subject="Facebook Share" url={shareUrl}>
        <FacebookIcon size={32} borderRadius={20} />
      </FacebookShareButton>
      <TwitterShareButton subject="Twitter Share" url={shareUrl}>
        <TwitterIcon size={32} borderRadius={20} />
      </TwitterShareButton>
      <LinkedinShareButton subject="LinkedIn Share" url={shareUrl}>
        <LinkedinIcon size={32} borderRadius={20} />
      </LinkedinShareButton>
      <EmailShareButton subject="Email Share" url={shareUrl}>
        <EmailIcon size={32} borderRadius={20} />
      </EmailShareButton>
      <TelegramShareButton subject="Telegram Share" url={shareUrl}>
        <TelegramIcon size={32} borderRadius={20} />
      </TelegramShareButton>
      <WhatsappShareButton subject="Whatsapp Share" url={shareUrl}>
        <WhatsappIcon size={32} borderRadius={20} />
      </WhatsappShareButton>
    </Social>
  )
}

const Social = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  overflow: hidden;
`

export default SocialShare
