import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Navbar from '../components/Navbar'
// import Footer from '../components/Footer'
import UserService from '../services/UserService'
import UserHelper from '../helpers/UserHelper'
import ArticleService from '../services/ArticleService'
import ArticleList from '../components/ArticleList'

const Author = () => {
  const [author, setAuthor] = useState('')
  const [articles, setArticles] = useState('')
  const location = useLocation()

  useEffect(async () => {
    const authorId = location.pathname.split('/')[2]
    await UserService.getSingleUser(authorId).then((data) => {
      setAuthor(data)
    })
    await ArticleService.getAllUserArticles(authorId).then((data) => {
      setArticles(data)
    })
  }, [])

  return (
    <Wrapper>
      <Navbar />
      <h2 style={{ margin: '2em' }}>
        All articles {author && UserHelper.getFullName(author)} has published:
      </h2>
      <ArticleList
        articles={articles}
        setArticles={setArticles}
        setArticlesCopy={setArticles}
      ></ArticleList>
    </Wrapper>
  )
}

export default Author

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
`
