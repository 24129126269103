/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { useHistory, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import ArticleService from '../services/ArticleService'
import UserService from '../services/UserService'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  margin20: {
    marginTop: '20px',
  },
  margin10: {
    marginTop: '10px',
  },
}))

const Review = () => {
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [reviews, setReviews] = useState(null)
  const [comment, setComment] = useState('')
  const [reviewerNo1, setReviewerNo1] = useState(null)
  const [reviewerNo2, setReviewerNo2] = useState(null)

  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const handleAccept = async () => {
    await ArticleService.approveArticle({
      ...selectedArticle,
      approved: true,
    }).then(async () => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      } else {
        const comment1 =
          reviews[0] && reviews[0].comment ? reviews[0].comment : ''
        const comment2 =
          reviews[1] && reviews[1].comment ? reviews[1].comment : ''
        const reviewerName1 =
          reviewerNo1 && `${reviewerNo1.first_name} ${reviewerNo1.last_name}`
        const reviewerName2 =
          reviewerNo2 && `${reviewerNo2.first_name} ${reviewerNo2.last_name}`
        const reviewerEmail1 = reviewerNo1 && reviewerNo1.email
        const reviewerEmail2 = reviewerNo2 && reviewerNo2.email

        const data = {
          from: 'selman@procerasoft.com',
          to: selectedArticle.user.email,
          name: `${selectedArticle.user.first_name} ${selectedArticle.user.last_name}`,
          articleID: selectedArticle.id,
          articleTitle: selectedArticle.title,
          reviewerComment1: comment1,
          reviewerComment2: comment2,
          reviewerName1,
          reviewerEmail1,
          reviewerName2,
          reviewerEmail2,
        }
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/article/accept`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((res) => {
          res.json()
          setTimeout(() => {
            history.push('/admin/articles')
          }, 3000)
        })
      }
    })
  }

  const handleReject = async () => {
    await ArticleService.deleteArticle(selectedArticle.id).then(async () => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      }
      const comment1 =
        reviews[0] && reviews[0].comment ? reviews[0].comment : ''
      const comment2 =
        reviews[1] && reviews[1].comment ? reviews[1].comment : ''
      const reviewerName1 =
        reviewerNo1 && `${reviewerNo1.first_name} ${reviewerNo1.last_name}`
      const reviewerName2 =
        reviewerNo2 && `${reviewerNo2.first_name} ${reviewerNo2.last_name}`
      const reviewerEmail1 = reviewerNo1 && reviewerNo1.email
      const reviewerEmail2 = reviewerNo2 && reviewerNo2.email

      const data = {
        from: 'selman@procerasoft.com',
        to: selectedArticle.user.email,
        name: `${selectedArticle.user.first_name} ${selectedArticle.user.last_name}`,
        articleID: selectedArticle.id,
        articleTitle: selectedArticle.title,
        reviewerComment1: comment1,
        reviewerComment2: comment2,
        reviewerName1,
        reviewerEmail1,
        reviewerName2,
        reviewerEmail2,
      }
      await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/article/reject`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json()
        setTimeout(() => {
          history.push('/admin/articles')
        }, 3000)
      })
    })
    await ArticleService.deleteVideo(selectedArticle.video_url)
  }

  const handleRevise = async () => {
    const comment1 = reviews[0] && reviews[0].comment ? reviews[0].comment : ''
    const comment2 = reviews[1] && reviews[1].comment ? reviews[1].comment : ''
    const reviewerName1 =
      reviewerNo1 && `${reviewerNo1.first_name} ${reviewerNo1.last_name}`
    const reviewerName2 =
      reviewerNo2 && `${reviewerNo2.first_name} ${reviewerNo2.last_name}`
    const reviewerEmail1 = reviewerNo1 && reviewerNo1.email
    const reviewerEmail2 = reviewerNo2 && reviewerNo2.email

    const data = {
      from: 'selman@procerasoft.com',
      to: selectedArticle.user.email,
      name: `${selectedArticle.user.first_name} ${selectedArticle.user.last_name}`,
      articleID: selectedArticle.id,
      articleTitle: selectedArticle.title,
      reviewerComment1: comment1,
      reviewerComment2: comment2,
      adminComment: comment,
      reviewerName1,
      reviewerEmail1,
      reviewerName2,
      reviewerEmail2,
    }
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/article/revise`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json()
      setTimeout(() => {
        history.push('/admin/articles')
      }, 3000)
    })
  }

  const handleCommentChange = (event) => setComment(event.target.value)

  useEffect(async () => {
    await ArticleService.getArticle(location.pathname.split('/')[2]).then(
      async (data) => {
        setSelectedArticle(data)
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/reviews/${data.id}`,
        ).then((res) => {
          res.json().then(async (resJSON) => {
            setReviews(resJSON)

            if (resJSON[0])
              await UserService.getSingleUser(resJSON[0].userId).then(
                (data1) => {
                  setReviewerNo1(data1)
                },
              )
            if (resJSON[1])
              await UserService.getSingleUser(resJSON[1].userId).then(
                (data2) => {
                  setReviewerNo2(data2)
                },
              )
          })
        })
      },
    )
  }, [])

  return (
    <>
      <Container component="main">
        <CssBaseline />
        <div className={classes.root}>
          <Typography
            component="h4"
            variant="h4"
            style={{ fontWeight: 'bold', margin: '20px 0' }}
          >
            Reviews
          </Typography>
          <Reviews>
            <Paper elevation={1}>
              <ReviewCard>
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: 'bold' }}
                  className={classes.margin20}
                >
                  Reviewer no. 1:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Name:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviewerNo1 &&
                      `${reviewerNo1.first_name} ${reviewerNo1.last_name}
                    
            `}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  E-mail:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviewerNo1 && reviewerNo1.email}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'large', fontWeight: 'bold' }}
                  className={classes.margin10}
                >
                  Scoring:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Video Quality:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].video_quality}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Video Length
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].video_length}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Audio:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].audio}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Recommendation:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].recommendation}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Clinical Description:{' '}
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {reviews && reviews[0] && reviews[0].clinical_description}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Concise and clear presentation of the case
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Imaging:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].imaging}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Patient identifiers removed, high quality, highlighted anatomy
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Results:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].results}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Patient outcome easily understood or visualized
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Conclusions:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[0] && reviews[0].conclusions}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Authors do not overstate their results and clarify the
                  implications of the video
                </Typography>
                {/* <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Confidential Comments to the EIC:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  {reviews && reviews[0] && reviews[0].comment}
                </Typography> */}
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Public comment (for the author):
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  {reviews && reviews[0] && reviews[0].comment}
                </Typography>

                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Confidental comment (for the editor):
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  {reviews && reviews[0] && reviews[0].confidental_comment}
                </Typography>
              </ReviewCard>
            </Paper>
            <Paper elevation={1}>
              <ReviewCard>
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: 'bold' }}
                  className={classes.margin20}
                >
                  Reviewer no. 2:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Name:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviewerNo2 &&
                      `${reviewerNo2.first_name} ${reviewerNo2.last_name}
                    
            `}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  E-mail:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviewerNo2 && reviewerNo2.email}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'large', fontWeight: 'bold' }}
                  className={classes.margin10}
                >
                  Scoring:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Video Quality:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].video_quality}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Video Length
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].video_length}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Audio:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].audio}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Recommendation:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].recommendation}
                  </Typography>
                </Typography>

                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Clinical Description:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].clinical_description}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Concise and clear presentation of the case
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Imaging:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].imaging}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Patient identifiers removed, high quality, highlighted anatomy
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Results:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].results}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Patient outcome easily understood or visualized
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Conclusions:
                  <Typography
                    component="body1"
                    variant="body1"
                    style={{ fontSize: 'large', fontWeight: 'bold' }}
                  >
                    {' '}
                    {reviews && reviews[1] && reviews[1].conclusions}
                  </Typography>
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontSize: 'small', fontWeight: '200' }}
                >
                  Authors do not overstate their results and clarify the
                  implications of the video
                </Typography>
                {/* <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Confidential Comments to the EIC:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  {reviews && reviews[1] && reviews[1].comment}
                </Typography> */}
                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Comments to the Author:
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  {reviews && reviews[1] && reviews[1].comment}
                </Typography>

                <Typography
                  component="body1"
                  variant="body1"
                  className={classes.margin10}
                >
                  Confidental comment (for the editor):
                </Typography>
                <Typography
                  component="body1"
                  variant="body1"
                  style={{ fontWeight: 'bold' }}
                >
                  {' '}
                  {reviews && reviews[1] && reviews[1].confidental_comment}
                </Typography>
              </ReviewCard>
            </Paper>
          </Reviews>
          <ReviewOptions>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleAccept}
            >
              Accept
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              style={{ color: '#FF0000' }}
              onClick={handleReject}
            >
              Reject
            </Button>
            <Typography
              component="body1"
              variant="body1"
              style={{ fontSize: 'large', marginTop: '20px' }}
            >
              Leave a comment for revision, which will be sent via e-mail, along
              with reviewer comments:
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="comment"
              label="Comment"
              name="comment"
              value={comment}
              onChange={handleCommentChange}
              multiline
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="default"
              onClick={handleRevise}
              style={{ marginTop: '10px', marginBottom: '20px' }}
            >
              Designate for Revision
            </Button>
          </ReviewOptions>
        </div>
      </Container>
    </>
  )
}

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px 25px 25px;
`
const Reviews = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    margin: 20px;
  }
`
const ReviewOptions = styled.div`
  display: flex;
  margin: auto;
  width: 50%;
  flex-direction: column;
`

export default Review
