/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useHistory, useLocation } from 'react-router-dom'
import AuthService from '../services/AuthService'
import Navbar from '../components/Navbar'
import Validator from '../helpers/Validations'

export const Form = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [error, setError] = useState({})
  const classes = useStyles()

  const history = useHistory()
  const location = useLocation()

  const handleFirstNameChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        firstName: 'First name is required!',
      })
    } else if (!Validator.max50CharsValidate(event.target.value)) {
      setError({
        ...error,
        firstName: 'Maximum number of characters allowed is 50!',
      })
    } else {
      setError({
        ...error,
        firstName: '',
      })
    }

    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        lastName: 'Last name is required!',
      })
    } else if (!Validator.max50CharsValidate(event.target.value)) {
      setError({
        ...error,
        lastName: 'Maximum number of characters allowed is 50!',
      })
    } else {
      setError({
        ...error,
        lastName: '',
      })
    }
    setLastName(event.target.value)
  }

  const handleEmailChange = (event) => {
    if (!Validator.emailValidate(event.target.value)) {
      setError({
        ...error,
        email: 'Please enter a valid email address!',
      })
    } else {
      setError({
        ...error,
        email: '',
      })
    }
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event) => {
    if (!Validator.passwordValidate(event.target.value)) {
      setError({
        ...error,
        password: 'Password must be between 6 and 50 characters!',
      })
    } else {
      setError({
        ...error,
        password: '',
      })
    }
    setPassword(event.target.value)
  }

  const handleConfirmedPasswordChange = (event) => {
    if (!Validator.passwordValidate(event.target.value)) {
      setError({
        ...error,
        confirmedPassword: 'Password must be between 6 and 50 characters!',
      })
    } else if (event.target.value !== password) {
      setError({
        ...error,
        confirmedPassword: "Password's must match!",
      })
    } else {
      setError({
        ...error,
        confirmedPassword: '',
      })
    }
    setConfirmedPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let body = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    }
    if (location.pathname.split('/')[2] === 'reviewer')
      body = { ...body, role: 2 }
    AuthService.register(body)
      .then(() => {
        history.push('/')
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (localStorage.getItem('token')) history.push('/')
  }, [])

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={handleFirstNameChange}
            autoFocus
            error={error.firstName}
            helperText={error.firstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            error={error.lastName}
            helperText={error.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            autoComplete="email"
            error={error.email}
            helperText={error.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            type="password"
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            autoComplete="password"
            error={error.password}
            helperText={error.password}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            type="password"
            margin="normal"
            color="secondary"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            autoComplete="password"
            value={confirmedPassword}
            onChange={handleConfirmedPasswordChange}
            error={error.confirmedPassword}
            helperText={error.confirmedPassword}
          />
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={
            error.email ||
            error.password ||
            error.confirmedPassword ||
            error.firstName ||
            error.lastName ||
            firstName === '' ||
            lastName === '' ||
            email === '' ||
            password === '' ||
            confirmedPassword === ''
          }
        >
          REGISTER
        </Button>
        <Typography variant="body2">All * fields required</Typography>
      </Grid>
    </form>
  )
}

const RegisterPage = () => {
  const classes = useStyles()
  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register account
          </Typography>
          <Form />
          <Grid container>
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Log In
              </Link>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default RegisterPage
