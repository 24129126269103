import React, { useState } from 'react'
import { NotificationContainer } from 'react-notifications'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AuthService from './services/AuthService'
import HomePage from './pages/HomePage'
import ArticlesPage from './pages/ArticlesPage'
import ArticleUpload from './pages/ArticleUpload'
import LoginPage from './pages/LoginPage'
import RegisterPage from './pages/RegisterPage'
import Article from './pages/Article'
import './App.css'
import ManageArticles from './components/ManageArticles'
import { AdminPanel } from './pages/AdminPanel'
import PrivateRoute from './components/PrivateRoute'
import AboutUsPage from './pages/AboutUsPage'
import ScrollToTop from './components/ScrollToTop'
import EditorialBoardPage from './pages/EditorialBoardPage'
import TermsPage from './pages/TermsPage'
import PrivacyPage from './pages/PrivacyPage'
import AdminPanelContext from './context/AdminPanelContext'
import AddEditUser from './components/AddEditUser'
import Users from './components/Users'
import ArticlesBrowseBy from './components/ArticlesBrowseBy'
import Author from './pages/Author'
import EmailAccept from './pages/EmailAccept'
import EmailDecline from './pages/EmailDecline'
import ReviewerEmailInviteForm from './components/ReviewerEmailInviteForm'
import PasswordEmail from './pages/PasswordEmail'
import ResetPassword from './pages/ResetPassword'
import { ReviewerPanel } from './pages/ReviewerPanel'
import ReviewArticles from './components/ReviewArticles'
import UserEmailInviteForm from './components/UserEmailInviteForm'
import ReviewDecline from './components/ReviewDecline'

function App() {
  const [adminPage, setAdminPage] = useState(0)
  const [actionEdit, setActionEdit] = useState(0)
  const [userID, setUserID] = useState(null)
  const [editedArticle, setEditedArticle] = useState(null)
  const [toEditArticle, setToEditArticle] = useState(false)
  const [filters, setFilters] = useState({
    category: null,
    year: null,
    search: '',
  })
  const [reviewArticle, setReviewArticle] = useState(0)
  const [reviewListID, setReviewListID] = useState(null)

  const isExpireToken = () => {
    if (localStorage.getItem('token') != null) {
      const token = localStorage.getItem('token')
      AuthService.verify(token).then((err) => {
        if (err === false) {
          localStorage.removeItem('token')
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      })
    }
  }

  const removeUserList = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/get-and-delete`,
      requestOptions,
    )
  }

  isExpireToken()
  removeUserList()

  return (
    <Router>
      <AdminPanelContext.Provider
        value={{
          adminPage,
          setAdminPage,
          actionEdit,
          setActionEdit,
          userID,
          setUserID,
          editedArticle,
          setEditedArticle,
          toEditArticle,
          setToEditArticle,
          filters,
          setFilters,
          reviewArticle,
          setReviewArticle,
          reviewListID,
          setReviewListID,
        }}
      >
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage} key="home" />
          <Route exact path="/articles" component={ArticlesPage} key="videos" />
          <Route
            exact
            path="/articles/categories"
            component={ArticlesBrowseBy}
          />

          <Route exact path="/article-upload" component={ArticleUpload} />
          <Route exact path="/article/revise/:id" component={ArticleUpload} />
          <Route exact path="/invite-user" component={UserEmailInviteForm} />

          <Route
            exact
            path={[
              '/article/:id',
              '/article/:id/accept/:reviewerID',
              '/article/:id/accepted',
            ]}
            component={Article}
          />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/password-form" component={PasswordEmail} />
          <Route exact path="/reset-password/:id" component={ResetPassword} />
          <Route exact path="/register" component={RegisterPage} />
          <PrivateRoute exact path={['/admin', '/admin/users']}>
            <>
              <AdminPanel />
              <Users />
            </>
          </PrivateRoute>
          <PrivateRoute exact path="/admin/user">
            <>
              <AdminPanel />
              <AddEditUser />
            </>
          </PrivateRoute>
          <PrivateRoute exact path="/admin/articles">
            <>
              <AdminPanel />
              <ManageArticles />
            </>
          </PrivateRoute>
          <PrivateRoute exact path="/admin/article">
            <>
              <AdminPanel />
              <ArticleUpload />
            </>
          </PrivateRoute>
          <PrivateRoute exact path={['/reviewer', '/reviewer/articles']}>
            <>
              <ReviewerPanel />
              <ReviewArticles />
            </>
          </PrivateRoute>
          <Route exact path="/approved-videos" component={ManageArticles} />
          <Route exact path="/about" component={AboutUsPage} />
          <Route
            exact
            path="/editorial-board"
            component={EditorialBoardPage}
            key="home"
          />
          <Route exact path="/terms-and-conditions" component={TermsPage} />

          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/author/:id" component={Author} />
          <Route exact path="/email-accept/:id" component={EmailAccept} />
          <Route exact path="/email-decline/:id" component={EmailDecline} />
          <Route
            exact
            path="/invite-reviewer"
            component={ReviewerEmailInviteForm}
          />
          <Route exact path="/register/reviewer" component={RegisterPage} />

          <Route
            exact
            path="/review-decline/:id/:userID"
            component={ReviewDecline}
          />
        </Switch>
        <NotificationContainer />
      </AdminPanelContext.Provider>
    </Router>
  )
}

export default App
