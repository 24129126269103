import React from 'react'

const AdminPanelContext = React.createContext({
  adminPage: 0,
  setAdminPage: null,
  actionEdit: 0,
  setActionEdit: null,
  userID: null,
  setUserID: null,
  editedArticle: null,
  setEditedArticle: null,
  toEditArticle: false,
  setToEditArticle: null,
  filters: {
    category: null,
    year: null,
    search: '',
  },
  setFilters: null,
  reviewArticle: 0,
  setReviewArticle: null,
  reviewListID: null,
  setReviewListID: null,
})

export default AdminPanelContext
