import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import ReactPlayer from 'react-player/vimeo'
import styled from 'styled-components'
import { createMuiTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Box } from '@material-ui/core'
import jwtDecode from 'jwt-decode'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ArticleService from '../services/ArticleService'
import ArticleMarkdown from '../components/ArticleMarkdown'
import ArticleInfo from '../components/ArticleInfo'
import Comments from '../components/Comment'
import useMediaQuery from '../mediaQuery'
import ReviewForm from '../components/ReviewForm'
import Review from '../components/Review'
import SetReviewers from '../components/SetReviewers'
import AdminPanelContext from '../context/AdminPanelContext'

const Article = () => {
  const [article, setArticle] = useState('')
  const [category, setCategory] = useState('')
  const [showReviewsForm, setShowReviewsForm] = useState(false)
  const [showReviews, setShowReviews] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const theme = createMuiTheme()
  const Mobile = useMediaQuery('(max-width: 768px)')
  const context = React.useContext(AdminPanelContext)

  theme.root = {
    margin: 0,
    padding: 0,
  }

  const getUserRole = () => {
    if (localStorage.getItem('token')) {
      const decodedID = jwtDecode(localStorage.getItem('token'))
      return decodedID.user.role
    }
    return null
  }

  const createInvite = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        articleId: location.pathname.split('/')[2],
        userId: location.pathname.split('/')[4],
      }),
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/invite`,
      requestOptions,
    ).then((res1) => {
      res1.json()
    })
  }

  useEffect(async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/invite/review/${
        location.pathname.split('/')[4]
      }/${location.pathname.split('/')[2]}`,
    ).then((res1) => {
      res1.json().then(async (data) => {
        // start

        if (!(data.message === 'ok' || data.message === 'not ok')) {
          if (location.pathname.split('/')[3] === 'accept') {
            context.setReviewArticle(1)

            let reviewer
            await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/users/${
                location.pathname.split('/')[4]
              }`,
              // eslint-disable-next-line no-return-assign
            ).then((res2) =>
              res2.json().then((data1) => {
                reviewer = data1
                return null
              }),
            )

            const requestOptions = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                from: 'selman@procerasoft.com',
                to: reviewer.email,
                name: `${reviewer.first_name} ${reviewer.last_name}`,
                articleID: location.pathname.split('/')[2],
              }),
            }
            await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/email/review/invite/accepted`,
              requestOptions,
            ).then((res3) => {
              res3.json()
            })

            createInvite()
          }
        } else if (data.message === 'not ok') {
          history.push('/')
        }

        // stop
      })
    })

    if (
      location.pathname.split('/')[3] === 'accepted' ||
      location.pathname.split('/')[3] === 'accept'
    )
      context.setReviewArticle(1)

    const articleId = location.pathname.split('/')[2]
    await ArticleService.getArticle(articleId).then(async (articleData) => {
      setArticle(articleData)
      await ArticleService.getCategory(articleData.categoryId).then(
        (categoryData) => {
          setCategory(categoryData.title)
        },
      )
    })

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/articles/${articleId}`,
      { headers: { 'x-auth-token': localStorage.token } },
    ).then((data) => {
      data.json().then((dataJSON) => {
        if (dataJSON.length === 0) {
          setShowReviewsForm(true)
        }
      })
    })

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviews/${articleId}`,
    ).then((data) => {
      data.json().then((dataJSON) => {
        if (dataJSON.length !== 0) {
          setShowReviews(true)
        }
      })
    })
  }, [])

  return (
    <Wrapper>
      <Navbar />
      <Box p={2} style={Mobile ? box : null}>
        {article && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box ml={2} mr={2}>
                <Container component={Paper} style={Mobile ? paper : null}>
                  <Box p={2} pt={4}>
                    <Typography
                      variant="h4"
                      style={{ wordBreak: 'break-word' }}
                    >
                      {article.title}
                    </Typography>
                    <Typography variant="body1">
                      {article.short_description}
                    </Typography>

                    <Box p={2} mb={2}>
                      <ReactPlayer
                        url={`https://player.vimeo.com/video/${article.video_url}`}
                        controls
                        width="100%"
                      />
                    </Box>
                    <ArticleMarkdown>{article.content}</ArticleMarkdown>
                  </Box>
                </Container>
                {!Mobile &&
                  (context.reviewArticle === 1 &&
                  article.approved === false &&
                  getUserRole() === 1 &&
                  (!showReviewsForm || showReviews) ? (
                    <Review></Review>
                  ) : (
                    article && <Comments article={article} />
                  ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <ArticleInfo
                date={article.date.slice(0, 10)}
                title={article.title}
                author={article.user}
                link={window.location.href}
                category={category}
                keywords={article.keywords}
              />
              {context.reviewArticle === 1 && getUserRole() === 2 ? (
                <ReviewForm></ReviewForm>
              ) : null}
              {getUserRole() === 1 &&
                article.approved === false &&
                showReviewsForm &&
                !showReviews && <SetReviewers></SetReviewers>}
            </Grid>
          </Grid>
        )}
      </Box>
      {Mobile &&
        (context.reviewArticle === 1 &&
        article.approved === false &&
        getUserRole() === 1 &&
        (!showReviewsForm || showReviews) ? (
          <Review></Review>
        ) : (
          article && <Comments customStyle={comment} article={article} />
        ))}
      <Footer />
    </Wrapper>
  )
}

export default Article

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
`
const box = {
  padding: '25px 0',
}
const comment = {
  margin: '15px',
}

const paper = {
  padding: '0',
}
