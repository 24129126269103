import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import video from '../assets/videos/video.mp4'

const Header = () => (
  <Video>
    <video src={video} autoPlay loop playsInline muted />
    <Wrapper>
      <Content>
        <h1>Journal of Techniques and Innovations in Pediatrics</h1>
        <Link to={localStorage.getItem('token') ? '/article-upload' : '/login'}>
          Submit new article
        </Link>
      </Content>
    </Wrapper>
  </Video>
)

export default Header

const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
`

const Video = styled.div`
  video {
    object-fit: cover;
    width: 100%;
    height: calc(70vh + 84px);
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -100;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  user-select: none;

  h1 {
    text-transform: uppercase;
    font-family: 'Marcellus', sans-serif;
    font-size: 52px;
    width: 800px;
    margin: 20px;
    text-align: center;
  }

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 13px;
  }

  a {
    width: 218px;
    height: 36px;
    background: grey;
    border: 2px solid grey;
    border-radius: 10px;
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: larger;
    font-weight: 900;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 36px;
    transition: all 0.1s ease-in;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 768px) {
    h1 {
      width: auto;
      font-size: 28px;
    }
  }
`
