import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const EditorsData = [
  {
    name: 'Megan Durham',
    title: 'Atlanta, USA',
  },
  {
    name: 'Mark Wulkan',
    title: 'Akron, USA',
  },
  {
    name: 'Todd Ponsky',
    title: 'Cincinnati, USA',
  },
  {
    name: 'Belinda Dickie',
    title: 'Boston, USA',
  },
  {
    name: 'Carolina Milan',
    title: 'Buenos Aires, Argentina',
  },
  {
    name: 'Hanna Alemayehu',
    title: 'Mobile, USA',
  },
]

const ContinentData = [
  {
    continent: 'Europe',
    name: 'Oliver Muensterer',
    title: 'Munich, Germany',
  },
  {
    continent: 'Latin America',
    name: 'Miguel Guelfand',
    title: 'Santiago, Chile',
  },
  {
    continent: 'Asia',
    name: 'Satoshi Ieri',
    title: 'Tokyo, Japan',
  },
]

const UrologyData = [
  {
    name: 'Irina Stanasel',
    title: 'Dallas, USA',
  },
  {
    name: 'Sabine Zundel',
    title: 'Lucerne, Switzerland',
  },
]

const InterventionalGastroEnterologyData = [
  {
    name: 'David Troendle',
    title: 'Dallas, USA',
  },
]

const RoboticsData = [
  {
    name: 'Gursev Sandlas',
    title: 'Mumbai, India',
  },
  {
    name: 'John Meehan',
    title: 'Seattle, USA',
  },
]

const InterventionalRadiologyData = [
  {
    name: 'Ricard Restrepo',
    title: 'Miami, USA',
  },
]

const AssociateEditors = () => {
  const classes = useStyles()
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={5}>
          {ContinentData &&
            ContinentData.map((data) => (
              <Grid key={data} item>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  className={classes.headingTitle}
                >
                  Associate Editor for
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  className={classes.headingFocused}
                >
                  {data.continent}
                </Typography>
                <Paper className={classes.paper}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        className={classes.heading}
      >
        Associate Editors
      </Typography>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={5}>
          {EditorsData &&
            EditorsData.map((data) => (
              <Grid key={data} item>
                <Paper className={classes.paper}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        className={classes.heading}
      >
        Associate Editors for Urology
      </Typography>
      <div></div>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={5}>
          {UrologyData &&
            UrologyData.map((data) => (
              <Grid key={data} item>
                <Paper className={classes.paper}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        className={classes.heading}
      >
        Associate Editors for Interventional Gastroenterology
      </Typography>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={5}>
          {InterventionalGastroEnterologyData &&
            InterventionalGastroEnterologyData.map((data) => (
              <Grid key={data} item>
                <Paper className={classes.paper}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        className={classes.heading}
      >
        Associate Editors for Robotics
      </Typography>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={5}>
          {RoboticsData &&
            RoboticsData.map((data) => (
              <Grid key={data} item>
                <Paper className={classes.paper}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        className={classes.heading}
      >
        Associate Editors for Interventional Radiology
      </Typography>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={5}>
          {InterventionalRadiologyData &&
            InterventionalRadiologyData.map((data) => (
              <Grid key={data} item>
                <Paper className={classes.paper}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '50px 0',
  },
  paper: {
    width: '300px',
    marginBottom: '20px',
    paddingBottom: '10px',
  },
  typography: {
    marginLeft: '10px',
    paddingTop: '5px',
    marginBottom: '5px',
  },
  heading: {
    marginLeft: '10%',
    color: 'rgb(85,187,241)',
    fontSize: '2.35rem',
  },
  headingTitle: {
    fontSize: '1.35rem',
    color: '#888888',
    fontWeight: '400',
    textAlign: 'center',
  },
  headingFocused: {
    fontSize: '2.35rem',
    color: 'rgb(85,187,241)',
    textAlign: 'center',
    marginBottom: '50px',
  },
  control: {
    padding: theme.spacing(2),
  },
}))

export default AssociateEditors
