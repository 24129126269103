import { Avatar, Paper, Typography, Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import { makeStyles } from '@material-ui/core/styles'
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded'
import { useHistory } from 'react-router-dom'
import UserService from '../services/UserService'
import UserHelper from '../helpers/UserHelper'
import CommentService from '../services/CommentService'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
      padding: '10px',
    },
  },
}))

const CommentCard = ({ comment, getComments }) => {
  const history = useHistory()

  const [user, setUser] = useState(null)

  const classes = useStyles()

  useEffect(() => {
    UserService.getSingleUser(comment.userId).then((data) => {
      setUser(data)
    })
  }, [])

  const deleteComment = async () => {
    CommentService.deleteComment(comment.id).then(() => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      }
      getComments()
    })
  }

  const handleDelete = (event) => {
    event.preventDefault()
    deleteComment()
  }

  const getUserRole = () => {
    if (localStorage.getItem('token')) {
      const decodedID = jwtDecode(localStorage.getItem('token'))
      return decodedID.user.role
    }
    return null
  }

  return (
    <div className={classes.root}>
      <Paper elevation={1}>
        {user && (
          <UserHeader>
            <AvatarPart style={{ paddingLeft: '40px', paddingTop: '10px' }}>
              <Avatar style={{ width: '50px', height: '50px', marginTop: 0 }}>
                {UserHelper.getInitials(user)}
              </Avatar>
            </AvatarPart>
            <UserInfo>
              <NamePart>
                <Typography variant="h6" component="h2">
                  {UserHelper.getFullName(user)}
                </Typography>
              </NamePart>
              <DatePart>
                <Typography variant="body2" component="h2">
                  {comment.date.slice(0, 10)}
                </Typography>
              </DatePart>
            </UserInfo>
            {getUserRole() === 1 && (
              <DeleteComment>
                <Button onClick={handleDelete}>
                  <DeleteForeverRoundedIcon
                    style={{ fontSize: '20', color: '#FF0000' }}
                  />
                </Button>
              </DeleteComment>
            )}
          </UserHeader>
        )}

        <TextPart>
          <Typography
            variant="body1"
            component="h2"
            style={{ paddingLeft: '40px', paddingTop: '10px' }}
          >
            {comment.comment}
          </Typography>
        </TextPart>
      </Paper>
    </div>
  )
}
export default CommentCard

const UserHeader = styled.div`
  display: flex;
  flex-direction: row;
`

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`

const NamePart = styled.div`
  display: inline-block;
  margin-left: 10px;
`
const AvatarPart = styled.div`
  display: flex;
`
const TextPart = styled.div`
  margin-left: 60px;
  display: flex;
`
const DatePart = styled.div`
  margin-left: 10px;
  display: flex;
`
const DeleteComment = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  float: right;
  margin-top: 10px;
  Button:hover {
    width: 100%;
    color: #55bbf1;
  }
`
