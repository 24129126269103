import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'

import { useHistory } from 'react-router-dom'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default function CustomizedDialogs(props) {
  const history = useHistory()

  const handleClose = () => {
    props.setOpen()
    history.push('/')
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogContent dividers>
          <Typography
            variant="h5"
            color="secondary"
            style={{ marginBottom: '5px' }}
          >
            Your Video has been succesfully uploaded!
          </Typography>
          <Typography variant="h8">
            Please wait while one of our editors approves the video.
          </Typography>
          <br />
          <Typography variant="h8">
            After that&apos;s done you&apos;ll see it on our page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="secondary">
            Back To Home Screen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
