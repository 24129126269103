/* eslint-disable react/no-unescaped-entities */
import { NotificationManager } from 'react-notifications'
import React, { useEffect, useState } from 'react'
import { Button, CssBaseline, makeStyles, TextField } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Autocomplete from '@material-ui/lab/Autocomplete'
import UserService from '../services/UserService'
import UserHelper from '../helpers/UserHelper'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SetReviewers = () => {
  const classes = useStyles()
  const location = useLocation()

  const [users, setUsers] = useState([])
  const [reviewerNo1, setReviewerNo1] = useState(-1)
  const [reviewerNo2, setReviewerNo2] = useState(-1)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify({
        articleId: location.pathname.split('/')[2],
        firstUser: reviewerNo1,
        secondUser: reviewerNo2,
      }),
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list`,
      requestOptions,
    )
      .then((response) => {
        response.json().then(async (data) => {
          let reviewer1
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/${reviewerNo1}`,
            // eslint-disable-next-line no-return-assign
          ).then((res1) => res1.json().then((data1) => (reviewer1 = data1)))

          let reviewer2
          if (reviewerNo2 !== -1) {
            await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/users/${reviewerNo2}`,
              // eslint-disable-next-line no-return-assign
            ).then((res2) => res2.json().then((data2) => (reviewer2 = data2)))
          }

          const requestOptions1 = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              from: 'selman@procerasoft.com',
              to: reviewer1.email,
              name: `${reviewer1.first_name} ${reviewer1.last_name}`,
              articleID: location.pathname.split('/')[2],
              reviewerListID: data.id,
              reviewerID: reviewerNo1,
            }),
          }
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/email/review/invite`,
            requestOptions1,
          ).then((res1) => {
            res1.json()
          })

          if (reviewer2) {
            const requestOptions2 = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                from: 'selman@procerasoft.com',
                to: reviewer2.email,
                name: `${reviewer2.first_name} ${reviewer2.last_name}`,
                articleID: location.pathname.split('/')[2],
                reviewerListID: data.id,
                reviewerID: reviewerNo2,
              }),
            }
            await fetch(
              `${process.env.REACT_APP_BACKEND_URL}/api/email/review/invite`,
              requestOptions2,
            ).then((res1) => {
              res1.json()
            })
          }
        })

        NotificationManager.success('Reviewer(s) added successfully!', '', 2000)
      })
      .catch((err) => {
        NotificationManager.error(err)
      })
  }
  const getUsers = async () => {
    let data = await UserService.getUsers()
    data = data.filter((item) => item.role === 2)
    setUsers(data.sort((a, b) => a.first_name.localeCompare(b.first_name)))
  }

  useEffect(async () => {
    await getUsers()
  }, [])

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h4" variant="h5">
            Add Reviewer(s)
          </Typography>
          <form className={classes.form} noValidate>
            <Typography
              component="body1"
              variant="body1"
              style={{ fontSize: 'large' }}
            >
              Reviewer no. 1:
            </Typography>
            <br />
            <Autocomplete
              id="combo-box-demo"
              options={users}
              getOptionLabel={(option) =>
                `${UserHelper.getFullName(option)} (${option.email})`
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reviewer no. 1"
                  variant="outlined"
                />
              )}
              required
              fullWidth
              margin="normal"
              color="secondary"
              onChange={(event, newValue) => {
                setReviewerNo1(newValue && newValue.id)
              }}
            />
            <br />
            <Typography
              component="body1"
              variant="body1"
              style={{ fontSize: 'large' }}
            >
              Reviewer no. 2:
            </Typography>
            <br />
            <Autocomplete
              id="combo-box-demo"
              options={users}
              getOptionLabel={(option) =>
                `${UserHelper.getFullName(option)} (${option.email})`
              }
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reviewer no. 2"
                  variant="outlined"
                />
              )}
              required
              fullWidth
              margin="normal"
              color="secondary"
              onChange={(event, newValue) => {
                setReviewerNo2(newValue && newValue.id)
              }}
            />
            {reviewerNo1 === reviewerNo2 &&
              reviewerNo1 !== -1 &&
              reviewerNo2 !== -1 && (
                <Typography color="error">
                  You can't add the same reviewer twice!
                </Typography>
              )}
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={
                reviewerNo1 === -1 ||
                reviewerNo2 === -1 ||
                reviewerNo1 === reviewerNo2
              }
            >
              Add Review
            </Button>
          </form>
        </div>
      </Container>
    </>
  )
}

export default SetReviewers
