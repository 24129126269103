import React from 'react'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const AboutFoundingEditors = () => (
  <Container>
    <Box p={3}>
      <Typography variant="h4" style={{ fontWeight: '800' }}>
        Our mission
      </Typography>
      <Box mb={3} />
      <Typography variant="body1" align="justify">
        The founding editors of JTIPs shared a vision to create the world’s
        premier video journal specifically for pediatric techniques and
        innovations. They recognized the value of collaboration across multiple
        specialties in pediatric care and as such widened the scope of the
        journal to include material from myriad pediatric sub-specialties.
      </Typography>
      <Box mb={2} />
      <Typography variant="body1" align="justify">
        The journal’s mission is to create widely available videos of
        innovations, techniques, and technology that can be applied to the care
        of children globally. As such, to truly democratize this information, it
        is{' '}
        <b>
          the world’s first truly open-access pediatric video journal.
          Specifically, authors are not required to pay for publication, nor are
          readers required to pay for access.
        </b>
      </Typography>
      <Box mb={2} />
      <Typography variant="body1" align="justify">
        <i>
          “In the 21 st century, we feel strongly that every child deserves
          state-of-the-art healthcare. Our mission is to curate and educate
          providers by disseminating the knowledge.”
        </i>
      </Typography>
    </Box>
    <Box p={3}>
      <Typography variant="h4" style={{ fontWeight: '800' }}>
        About the Founding Editors:
      </Typography>
      <Box mb={3} />
      <Typography variant="body1" align="justify">
        Drs. Samir Pandya, Eduardo Perez, and Matthew Clifton are academic
        pediatric surgeons in the United States. With over thirty years of
        surgical experience, hundreds of peer-reviewed articles, book chapters,
        and technique videos between them, they have been consistent
        contributors to the advancement of pediatric surgical care. They have
        collaborated on multiple clinical, research, and educational projects.
        They are clinically active academic pediatric surgeons and specialize in
        minimally invasive pediatric surgical techniques for thoracic and
        abdominopelvic pathology.
      </Typography>
    </Box>
  </Container>
)

export default AboutFoundingEditors
