import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import ArticleService from '../services/ArticleService'
import ManageArticlesList from './ManageArticlesList'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '80%',
    minWidth: '736px',
    margin: '0 auto',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ManageArticles = () => {
  const [approvedArticles, setApprovedArticles] = useState([])
  const [unapprovedArticles, setUnapprovedArticles] = useState([])
  const [articles, setArticles] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const fetchArticles = async () => {
    const data = await ArticleService.getApproved()
    setApprovedArticles(data)

    const data2 = await ArticleService.getUnapproved()
    setUnapprovedArticles(data2)

    setArticles([...data, ...data2])
  }

  useEffect(async () => {
    fetchArticles()
  }, [])

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Approved" {...a11yProps(1)} />
          <Tab label="Not approved" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <ManageArticlesList
          articles={articles}
          selectedTab={selectedTab}
          fetchArticles={fetchArticles}
        ></ManageArticlesList>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <ManageArticlesList
          articles={approvedArticles}
          selectedTab={selectedTab}
          fetchArticles={fetchArticles}
        ></ManageArticlesList>
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <ManageArticlesList
          articles={unapprovedArticles}
          selectedTab={selectedTab}
          fetchArticles={fetchArticles}
        ></ManageArticlesList>
      </TabPanel>
    </div>
  )
}

export default ManageArticles
