const emailValidate = (email) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailRegex.test(email)
}

const passwordValidate = (password) =>
  password.length >= 6 && password.length <= 50

const titleValidate = (title) => title.length >= 3 && title.length <= 100

const descriptionValidate = (description) => description.length <= 255

const keywordsValidate = (keywords) => keywords >= 5

const max50CharsValidate = (input) => input.length <= 50

const max100CharsValidate = (input) => input.length <= 100

const required = (input) => input.length !== 0

export default {
  emailValidate,
  passwordValidate,
  titleValidate,
  descriptionValidate,
  keywordsValidate,
  max50CharsValidate,
  max100CharsValidate,
  required,
}
