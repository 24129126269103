import React, { useState } from 'react'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { Redirect } from 'react-router-dom'
import AdminPageDrawer from '../components/Drawer'

export const AdminPanel = () => {
  let role = null
  if (localStorage.getItem('token'))
    role = jwt_decode(localStorage.getItem('token')).user.role
  const [isAdmin] = useState(role === 1)

  return <>{isAdmin ? <AdminPageDrawer /> : <Redirect to="/" />}</>
}
