/* eslint-disable react/no-unescaped-entities */
import { NotificationManager } from 'react-notifications'
import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { useHistory } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Navbar from '../components/Navbar'

// this component send email with reset password button on submit

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

// this function get user by email and then send email with user email, id and name

const PasswordEmail = () => {
  const classes = useStyles()
  const history = useHistory()

  const [email, setEmail] = useState('')

  const handleEmailChange = (event) => setEmail(event.target.value)

  const handleEmailPassword = async () => {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/email/${email}`)
      .then((response) => response.json())
      .then(async (data) => {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/invite`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: data.id, password: true }),
        }).then(async () => {
          const mail = {
            from: 'selman@procerasoft.com',
            to: data.email,
            id: data.id,
            name: data.first_name,
          }
          await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/new-password`, {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(mail),
          }).then(() => {
            NotificationManager.success('Email was sent!', '', 2000)
            setTimeout(() => {
              history.push('/')
            }, 3000)
          })
        })
      })

    /*  */
  }

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter your email!
          </Typography>
          <form className={classes.form} noValidate method="POST">
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              autoFocus
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleEmailPassword}
            >
              Send Email
            </Button>
          </form>
        </div>
      </Container>
    </>
  )
}

export default PasswordEmail
