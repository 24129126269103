/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { useHistory } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Navbar from './Navbar'
import Validator from '../helpers/Validations'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const UserEmailInviteForm = () => {
  const classes = useStyles()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [error, setError] = useState({})

  const handleEmailChange = (event) => {
    if (!Validator.emailValidate(event.target.value)) {
      setError({
        ...error,
        email: 'Please enter a valid email address!',
      })
    } else {
      setError({
        ...error,
        email: '',
      })
    }
    setEmail(event.target.value)
  }

  const submitEmail = async () => {
    const data = {
      from: 'selman@procerasoft.com',
      to: email,
      name: fullName,
    }
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/email/user-invite`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((res) => {
      res.json()
      history.push('/admin/users')
    })
  }

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Enter recipient name and e-mail
          </Typography>
          <form className={classes.form} noValidate method="POST">
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="fullname"
              label="Full name"
              name="fullname"
              value={fullName}
              onChange={(event) => {
                if (!Validator.required(event.target.value)) {
                  setError({
                    ...error,
                    fullName: 'Full name is required!',
                  })
                } else if (!Validator.max100CharsValidate(event.target.value)) {
                  setError({
                    ...error,
                    fullName: 'Maximum number of characters allowed is 100!',
                  })
                } else {
                  setError({
                    ...error,
                    fullName: '',
                  })
                }
                setFullName(event.target.value)
              }}
              autoFocus
              error={error.fullName}
              helperText={error.fullName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleEmailChange}
              error={error.email}
              helperText={error.email}
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={submitEmail}
              disabled={error.email || error.fullName}
            >
              Send Invite
            </Button>
          </form>
        </div>
      </Container>
    </>
  )
}

export default UserEmailInviteForm
