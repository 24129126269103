import { Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

const ReviewDecline = () => {
  const [author, setAuthor] = useState(0)
  const location = useLocation()
  const history = useHistory()

  useEffect(async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/author/${
        location.pathname.split('/')[2]
      }/${location.pathname.split('/')[3]}`,
    ).then((res) => {
      res.json().then((message) => {
        if (message.message === 'first') {
          setAuthor(1)
        } else if (message.message === 'second') {
          setAuthor(2)
        }
      })
    })
  }, [])

  return (
    <Button
      onClick={async () => {
        let body
        if (author === 1) {
          body = { firstUser: -1 }
        }
        if (author === 2) {
          body = {
            secondUser: -1,
          }
        }
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            // 'x-auth-token': localStorage.getItem('token'),
          },
          body: JSON.stringify(body),
        }
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/${
            location.pathname.split('/')[2]
          }`,
          requestOptions,
        )
          .then(() => {
            history.push('/')
          })
          .catch((err) => {
            NotificationManager.error(err)
          })
      }}
    >
      Decline Review Invite
    </Button>
  )
}

export default ReviewDecline
