/* eslint-disable react/no-unescaped-entities */
import { NotificationManager } from 'react-notifications'
import jwtDecode from 'jwt-decode'
import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { useHistory, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import AdminPanelContext from '../context/AdminPanelContext'
import Validator from '../helpers/Validations'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const ReviewForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [videoQuality, setVideoQuality] = useState('')
  const [videoLength, setVideoLength] = useState('')
  const [audio, setAudio] = useState('')
  const [clinicalDescription, setClinicalDescription] = useState('')
  const [imaging, setImaging] = useState('')
  const [results, setResults] = useState('')
  const [conclusions, setConclusions] = useState('')
  const [recommendation, setRecommendation] = useState('')
  const [publicComment, setPublicComment] = useState('')
  const [confidentialComment, setConfidentialComment] = useState('')

  const [author, setAuthor] = useState(0)
  const [user, setUser] = useState('')
  const [error, setError] = useState({
    conclusions: true,
    results: true,
    imaging: true,
    description: true,
  })

  const context = React.useContext(AdminPanelContext)

  const handleVideoQualityChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        videoQuality: 'Video Quality is required!',
      })
    } else {
      setError({
        ...error,
        videoQuality: '',
      })
    }
    setVideoQuality(event.target.value)
  }
  const handleVideoLengthChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        videoLength: 'Video Length is required!',
      })
    } else {
      setError({
        ...error,
        videoLength: '',
      })
    }
    setVideoLength(event.target.value)
  }
  const handleAudioChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        audio: 'Audio is required!',
      })
    } else {
      setError({
        ...error,
        audio: '',
      })
    }
    setAudio(event.target.value)
  }
  const handleClinicalDescriptionChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        description: 'Description is required!',
      })
    } else {
      setError({
        ...error,
        description: '',
      })
    }
    setClinicalDescription(event.target.value)
  }
  const handleImagingChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        imaging: 'Imaging is required!',
      })
    } else {
      setError({
        ...error,
        imaging: '',
      })
    }
    setImaging(event.target.value)
  }
  const handleResultsChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        results: 'Results is required!',
      })
    } else {
      setError({
        ...error,
        results: '',
      })
    }
    setResults(event.target.value)
  }
  const handleConclusionsChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        conclusions: 'Conclusions is required!',
      })
    } else {
      setError({
        ...error,
        conclusions: '',
      })
    }
    setConclusions(event.target.value)
  }
  const handleRecommendationChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        recommendation: 'Recommendation is required!',
      })
    } else {
      setError({
        ...error,
        recommendation: '',
      })
    }
    setRecommendation(event.target.value)
  }
  const handlePublicCommentChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        comment: 'Comment is required!',
      })
    } else {
      setError({
        ...error,
        comment: '',
      })
    }
    setPublicComment(event.target.value)
  }

  const handleConfidentialCommentChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        comment: 'Comment is required!',
      })
    } else {
      setError({
        ...error,
        comment: '',
      })
    }
    setConfidentialComment(event.target.value)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const decodedID = jwtDecode(localStorage.getItem('token'))
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify({
        userId: decodedID.user.id,
        articleId: location.pathname.split('/')[2],
        video_quality: videoQuality,
        video_length: videoLength,
        audio,
        clinical_description: clinicalDescription,
        imaging,
        results,
        conclusions,
        recommendation,
        comment: publicComment,
        confidental_comment: confidentialComment,
      }),
    }
    const requestOptions3 = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from: 'selman@procerasoft.com',
        to: user.email,
        name: user.first_name,
        articleID: location.pathname.split('/')[2],
      }),
    }

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviews`,
      requestOptions,
    )
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/email/review-complete`,
      requestOptions3,
    )
    // start
    let body
    if (author === 1) {
      body = { firstUser: -1 }
    }
    if (author === 2) {
      body = {
        secondUser: -1,
      }
    }
    const requestOptions2 = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        // 'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/${context.reviewListID}`,
      requestOptions2,
    )
      .then((response) => {
        response.json()
        NotificationManager.success('Review added successfully!', '', 2000)
        setTimeout(() => {
          history.push('/reviewer/articles')
        }, 3000)
      })
      .catch((err) => {
        NotificationManager.error(err)
      })

    // end
  }

  useEffect(async () => {
    const decodedID = jwtDecode(localStorage.getItem('token'))

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/author/${context.reviewListID}/${decodedID.user.id}`,
    ).then((res) => {
      res.json().then((message) => {
        if (message.message === 'first') {
          setAuthor(1)
        } else if (message.message === 'second') {
          setAuthor(2)
        }
      })
    })
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/${decodedID.user.id}`,
    ).then((res) => {
      res.json().then((message) => {
        setUser(message)
      })
    })
  }, [])

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography
            component="h5"
            variant="h5"
            style={{ fontWeight: 'bold' }}
          >
            Add Review
          </Typography>
          <form className={classes.form} noValidate>
            <Typography
              component="h5"
              variant="h5"
              style={{ fontSize: 'large', fontWeight: 'bold' }}
            >
              Scoring:
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="secondary"
                required
              >
                Video Quality
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={videoQuality}
                onChange={handleVideoQualityChange}
                label="Video Quality"
                color="secondary"
                required
                error={error.videoQuality}
                helperText={error.videoQuality}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Excellent">Excellent</MenuItem>
                <MenuItem value="Very Good">Very Good</MenuItem>
                <MenuItem value="Acceptable">Acceptable</MenuItem>
                <MenuItem value="Unsuitable for publication">
                  Unsuitable for publication
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="secondary"
                required
              >
                Video Length
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={videoLength}
                onChange={handleVideoLengthChange}
                label="Video Length"
                color="secondary"
                required
                error={error.videoLength}
                helperText={error.videoLength}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Excellent">Excellent</MenuItem>
                <MenuItem value="Very Good">Very Good</MenuItem>
                <MenuItem value="Acceptable">Acceptable</MenuItem>
                <MenuItem value="Unsuitable for publication">
                  Unsuitable for publication
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="secondary"
                required
              >
                Audio
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={audio}
                onChange={handleAudioChange}
                label="Audio"
                color="secondary"
                required
                error={error.audio}
                helperText={error.audio}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Excellent">Excellent</MenuItem>
                <MenuItem value="Very Good">Very Good</MenuItem>
                <MenuItem value="Acceptable">Acceptable</MenuItem>
                <MenuItem value="Unsuitable for publication">
                  Unsuitable for publication
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="secondary"
                style={{ fontWeight: 'bold' }}
                required
              >
                Clinical Description
              </FormLabel>
              Concise and clear presentation of the case
              <RadioGroup
                aria-label="Clinical description"
                name="gender1"
                value={clinicalDescription}
                onChange={handleClinicalDescriptionChange}
                required
                error={error.description}
                helperText={error.description}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="secondary"
                style={{ fontWeight: 'bold' }}
                required
              >
                Imaging
              </FormLabel>
              Patient identifiers removed, high quality, highlighted anatomy
              <RadioGroup
                aria-label="Clinical description"
                name="gender1"
                value={imaging}
                onChange={handleImagingChange}
                required
                error={error.imaging}
                helperText={error.imaging}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="secondary"
                style={{ fontWeight: 'bold' }}
                required
              >
                Results
              </FormLabel>
              Patient outcome easily understood or visualized
              <RadioGroup
                aria-label="Clinical description"
                name="gender1"
                value={results}
                onChange={handleResultsChange}
                required
                error={error.results}
                helperText={error.results}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                color="secondary"
                style={{ fontWeight: 'bold' }}
                required
              >
                Conclusions
              </FormLabel>
              Authors do not overstate their results and clarify the
              implications of the video
              <RadioGroup
                aria-label="Clinical description"
                name="gender1"
                value={conclusions}
                onChange={handleConclusionsChange}
                required
                error={error.conclusions}
                helperText={error.conclusions}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                color="secondary"
                required
              >
                Recommendation
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={recommendation}
                onChange={handleRecommendationChange}
                label="Recommendation"
                color="secondary"
                required
                error={error.recommendation}
                helperText={error.recommendation}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Accept as is">Accept as is</MenuItem>
                <MenuItem value="Minor Revision">Minor Revision</MenuItem>
                <MenuItem value="Major Revision">Major Revision</MenuItem>
                <MenuItem value="Reject">Reject</MenuItem>
              </Select>
            </FormControl>
            <Typography
              component="h5"
              variant="h5"
              style={{ fontSize: 'large', fontWeight: 'bold' }}
            >
              Public comment (for the author):
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="publicComment"
              label="Public comment"
              name="publicComment"
              value={publicComment}
              onChange={handlePublicCommentChange}
              multiline
              error={error.publicComment}
              helperText={error.publicComment}
            />
            <Typography
              component="h5"
              variant="h5"
              style={{ fontSize: 'large', fontWeight: 'bold' }}
            >
              Confidential comment (for the Editor):
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="confidentialComment"
              label="Confidential Comment"
              name="confidentialComment"
              value={confidentialComment}
              onChange={handleConfidentialCommentChange}
              multiline
              error={error.confidentialComment}
              helperText={error.confidentialComment}
            />

            <Button
              type="button"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={
                error.videoQuality ||
                error.videoLength ||
                error.audio ||
                error.description ||
                error.imaging ||
                error.results ||
                error.recommendation ||
                error.conclusions ||
                error.publicComment ||
                error.confidentialComment
              }
            >
              Add Review
            </Button>
            <Typography variant="body2">All * fields required</Typography>
          </form>
        </div>
      </Container>
    </>
  )
}

export default ReviewForm
