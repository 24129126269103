import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ChiefEditors from '../components/ChiefEditors'
import AssociateEditors from '../components/AssociateEditors'

const EditorialBoardPage = () => (
  <>
    <Navbar />
    <ChiefEditors />
    <AssociateEditors />
    <Footer />
  </>
)

export default EditorialBoardPage
