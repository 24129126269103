import React from 'react'
import Container from '@material-ui/core/Container'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import { IconButton, TableBody, TableCell } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import ShareIcon from '@material-ui/icons/Share'
import { Link } from 'react-router-dom'
import SocialShare from './SocialShare'
import UserHelper from '../helpers/UserHelper'

const ArticleInfo = ({ title, author, date, link, category, keywords }) => (
  <Container>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>{title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Author</TableCell>
            <TableCell>
              {author?.id && (
                <Link to={`/author/${author.id}`}>
                  {UserHelper.getFullName(author)}
                </Link>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>{category}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Publish Date</TableCell>
            <TableCell>{date.slice(0, 10)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Keywords</TableCell>
            <TableCell>
              {keywords.map((chip) => (
                <Chip
                  label={chip}
                  size="small"
                  color="secondary"
                  style={{
                    margin: '3px',
                    color: 'white',
                    padding: '2px',
                    fontWeight: 'bold',
                  }}
                />
              ))}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Social Share</TableCell>
            <TableCell>
              <SocialShare />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Copy Link</TableCell>
            <TableCell>
              <IconButton
                onClick={async () => {
                  await navigator.clipboard.writeText(link)
                }}
              >
                <ShareIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Container>
)

export default ArticleInfo
