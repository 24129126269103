import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Logo from '../assets/images/JTIP_Emblem_RGB.eps.png'

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    overflow: 'hidden',
  },
  mainAccordion: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  nestedAccordion: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  accordionDetailsFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom: {
    marginBottom: '45px',
  },
}))

const AboutUsPage = (props) => {
  const isMobile = useMediaQuery({ query: `(max-width: 640px)` })
  const classes = useStyles()
  const aimsAndScope = useRef(null)
  const submission = useRef(null)
  // const permissions = useRef(null)

  const scrollToSection = () => {
    if (props.location.state && props.location.state.subsection === 'aims') {
      aimsAndScope.current.scrollIntoView({ behavior: 'smooth' })
    } else if (
      props.location.state &&
      props.location.state.subsection === 'submission'
    ) {
      submission.current.scrollIntoView({ behavior: 'smooth' })
    }
    // else if (
    //   props.location.state &&
    //   props.location.state.subsection === 'permissions'
    // )
    //   permissions.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (props.location.state) scrollToSection()
  }, [props.location.state])

  return (
    <Wrapper>
      <Navbar />
      <Container maxWidth="lg">
        <Row>
          <Typography component="h3" variant="h3" style={{ fontWeight: '800' }}>
            About JTIPs
          </Typography>
        </Row>
        <Row>
          <Typography component="p" style={p1}>
            Launched in 2021, The Journal of Techniques and Innovations in
            Pediatrics is the world’s first and premier <u>open-access</u> video
            journal dedicated to pediatric conditions. It is a peer-reviewed
            publication with an editorial board comprising of internationally
            acclaimed physicians and is supported by international partner
            societies. The journal publishes on topics of interest to all
            pediatric sub-specialties namely: general and thoracic surgery,
            gastroenterology, urology, interventional radiology, neurosurgery,
            otolaryngology, and orthopedics. The articles are multimedia-based
            with accompanying written abstracts.
          </Typography>

          {!isMobile && (
            <img
              src={Logo}
              alt="JTIP"
              width="200"
              height="200"
              style={{ marginRight: '6%' }}
            ></img>
          )}
        </Row>

        <Typography
          component="h3"
          variant="h3"
          style={{ fontWeight: '800' }}
          ref={aimsAndScope}
        >
          Aims and Scope
        </Typography>
        <Row>
          <Typography component="p">
            JTIPs aims to become the source of multimedia content for techniques
            and innovations in pediatric care. The journal is committed to
            serving as a central hub for high-quality content accepted from
            contributors worldwide. Our goal is cost-effective, of broad
            dissemination of important work using various distribution partners.{' '}
            <br /> <br />
            All videos pertaining to diagnostic or therapeutic intervention,
            innovations, or surgical techniques in pediatric care will be
            considered. Videos related to surgical anatomy, embryology,
            physiology, pathology, or radiology of conditions of interest are
            also acceptable. JTIPs will accept original videos containing
            material that has not been reported elsewhere. Exceptions are made
            for submission of work that has been submitted as an abstract or
            conference presentation. <br /> <br /> All submitting authors are
            encouraged to complete their submissions using an ORCID identifier.
            Please visit the <a href="https://orcid.org/">ORCID website</a> for
            more information, or to register.
          </Typography>
        </Row>

        <Row>
          <Typography
            component="h3"
            variant="h3"
            style={{ fontWeight: '800' }}
            ref={submission}
          >
            Submission Guidelines
          </Typography>
        </Row>
        <Row>
          <Typography component="p">
            <b>JTIPs</b> Editors-in-Chief Drs. Samir Pandya, Matthew Clifton,
            and Eduardo Perez can be contacted via email at j.of.tips@gmail.com.
            For submission questions, please contact them via email. <br />{' '}
            <br /> The journal employs a conventional single-blind reviewing
            policy in which the reviewers’ name is concealed from the author.{' '}
            <br /> <br />
            Submissions must not be under simultaneous consideration by any
            other publication, before or during the peer-review process.
            Articles published in <b>JTIPs</b> may not be published elsewhere,
            other than the journal’s partner distributors, without the written
            permission from the publisher (PCP Publishing). The journal
            recognizes complete editorial independence from all partner
            societies. <br /> <br /> Only submissions that meet the aims and
            scope of <b>JTIPs</b> are reviewed. <b>JTIPs</b> utilizes a
            single-blind peer- review process for new submissions, which
            includes a minimum of two reviewers as well as one of the
            Editors-in-Chief. Revised submissions are reviewed by the
            Editors-in-Chief, who evaluate the revision and decide whether the
            authors have responded adequately to the reviewers’ comments and
            whether further revisions are required before the submission can be
            considered for publication. <br /> <br /> The following criteria are
            used in evaluating all submissions: quality of execution, visual
            presentation and audio narration and writing in addition to the
            contribution to current knowledge, acknowledgment of limitations,
            support of the Journal’s mission, and compliance with COPE and ICMJE
            guidelines for ethical publication. All submission decisions are
            approved by the Editors-in-Chief, who may request additional changes
            or modify the decision. Major corrections may require changes in the
            video with resubmission. <br /> <br /> All reviewers are required to
            update their disclosures each year to minimize potential reviewer
            bias. <br /> <br /> As part of the submission process, you will be
            required to warrant that you are submitting your original work, that
            you have the rights to the work, that you are submitting the work
            for first publication in the Journal, and that it is not being
            considered for publication elsewhere and has not already been
            published elsewhere (including on a media repository such as YouTube
            or VuMedi), and that you have obtained and can supply all necessary
            permissions for the reproduction of any copyright works not owned by
            you.
          </Typography>
        </Row>

        <Row>
          <Typography component="h3" variant="h3" style={{ fontWeight: '800' }}>
            Use of English Language
          </Typography>
        </Row>
        <Row>
          <Typography component="p">
            All submissions must be in English. Appropriate use of English is a
            requirement for review and publication in <b>JTIPs</b>. For
            non-native English speakers, we encourage you to use widely
            available translation services. Please note that employing the use
            of such a service is not mandatory and using it, or any other
            language editing service does not guarantee the acceptance of any
            paper. All submissions are subject to peer review.
          </Typography>
        </Row>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              1. Submission Guidelines
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              Submissions must not be under simultaneous consideration by any
              other publication, before or during the peer-review process, and
              cannot be uploaded to any preprint server or broad media
              repository (e.g., YouTube, VuMedi). Articles published in{' '}
              <b>JTIPs</b> may not be published elsewhere other than the
              journal’s partner distributors without the written permission from
              the publisher (PCP Publishing). The authors retain copyright under
              the terms outlined by the Creative Commons Attribution Open Access
              Noncommercial License Agreement. This license stipulates that
              anyone is free to copy, distribute, and display the article
              provided they give credit to the article. For more information on
              the Creative Commons Noncommercial Attribution License, please
              visit{' '}
              <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/">
                https://creativecommons.org/licenses/by-nc-nd/4.0/
              </a>
              . For detailed information pertaining to copyright and permissions
              requirements, view the Video Permission and Fair Use Guide. For
              videos with identifiable subjects, subjects will need to sign the
              Photography Release Form.{' '}
              <b>
                It is the author’s responsibility to submit signed release
                forms, if necessary, for each video.
              </b>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              2. Submissions
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetailsFlex}>
            <Typography component="p">
              The submission of a video requires the following:
              <ol type="a" style={{ lineHeight: '200%' }}>
                <li>Preparation of the video</li>
                <li>
                  Preparation of a document (containing title, abstract, and
                  transcript of the narration to accompany the video)
                </li>
                <li>Submitting the video and document</li>
                <li>
                  Replying and addressing comments from the review process
                </li>
              </ol>
              Authors should register on our online submission site at{' '}
              <a href="https://www.jtips.org">https://www.jtips.org</a> to
              submit manuscripts. <br />
              <br />
              When submissions have been received by the editorial office, the
              corresponding author will be sent an acknowledgment giving an
              assigned submission number, which should be used with all
              subsequent correspondence for anything related to that submission.
            </Typography>

            <Accordion className={classes.nestedAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: '800' }}
                >
                  2.1 Video Categories
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="p">
                  Types of videos encouraged include:
                  <ul style={{ lineHeight: '200%' }}>
                    <li>
                      Surgical Techniques <br /> Surgical techniques include
                      demonstrations of original or established techniques.
                    </li>
                    <li>
                      Endoscopic Techniques <br /> Endoscopic techniques include
                      demonstrations of original or established techniques.
                    </li>
                    <li>
                      Fluoroscopic Techniques
                      <br /> Fluoroscopic techniques include demonstrations of
                      original or established techniques.
                    </li>
                    <li>
                      Innovation Videos <br /> Videos demonstrating innovative
                      solutions to clinical problems.
                    </li>
                    <li>
                      Case Series <br /> Case series include is a group of case
                      reports involving patients who were given similar
                      treatment.
                    </li>
                    <li>
                      Other Treatment Procedures <br /> Other treatment
                      procedures may include any other procedure that does not
                      fit into the aforementioned types
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className={classes.nestedAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: '800' }}
                >
                  2.2 Preparation of the Video
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="p">
                  <ol type="a" style={{ lineHeight: '200%' }}>
                    <li>
                      Video submissions may be up to 7 minutes long in duration.
                      Invited videos/tutorials maybe longer (at the discretion
                      of the editorial team).
                    </li>
                    <li>
                      Videos can be uploaded in the <b>following formats</b>:
                      Windows Media Video (.WMV), MPEG (.MPG, .MPEG), Audio
                      Video Interleave (.AVI) and Quicktime (.MOV) formats.
                      Video dimensions must be at least 640x480 or higher for
                      best results. The video must also be in the NTSC format
                      (the European PAL format is not supported.)
                    </li>
                    <li>
                      A thumbnail image for their video with an aspect ratio of
                      16:9.
                    </li>
                    <li>
                      <b>
                        Tip for reducing video file size: Try using the H.246
                        codec
                      </b>
                      <br />A codec (compression-decompression program) called
                      H.246 comes with the Apple QuickTime Pro upgrade to
                      QuickTime 7 (the Pro upgrade costs $30 but QuickTime 7
                      itself is free){' '}
                      <a href="http://www.apple.com/quicktime/download/win.html">
                        http://www.apple.com/quicktime/download/win.html
                      </a>
                      . One journal reported that they created a 2MB video from
                      a 210MB file using the H.246 codec. Like any codec, this
                      one manages small file sizes through losses in compression
                      (and therefore potentially in image quality). Therefore,
                      if you choose to use the H.264 codec, please check the
                      settings to ensure the compression is set at the level you
                      are comfortable presenting. H.246 is typically quite
                      effective, even with HD video. See more information here:{' '}
                      <a href="http://www.apple.com/quicktime/technologies/h264/">
                        http://www.apple.com/quicktime/technologies/h264/
                      </a>
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.nestedAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: '800' }}
                >
                  2.3 Video Template
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="p">
                  Video slide decks should follow the below format and should
                  not exceed 7 minutes in length. Note that videos{' '}
                  <u>must be</u> narrated in English.
                  <ul style={{ lineHeight: '200%' }}>
                    <li>Title slide (including author information)</li>
                    <li>Disclosures</li>
                    <li>
                      Background: Patient presentation, evaluation,
                      surgical/treatment indications/contraindications
                    </li>
                    <li>Preoperative planning</li>
                    <li>Patient positioning and room setup (optional)</li>
                    <li>
                      Edited procedural video:{' '}
                      <b>
                        All patient identifiers must be removed prior to
                        submission
                      </b>
                    </li>
                    <li>Potential complications (how to avoid)</li>
                    <li>Postoperative course/outcome</li>
                    <li>Acknowledgments (if applicable)</li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.nestedAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: '800' }}
                >
                  2.4 Preparation of the Document
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="p">
                  <ol type="a" style={{ lineHeight: '200%' }}>
                    <li>Title page — Click here for a title page template.</li>
                    <li>
                      Written manuscript consisting of a formatted abstract,
                      video transcript, and references (no more than 5). Click
                      here for a transcript template.
                    </li>
                    <li>
                      A cover letter, acknowledgments, and suggested reviewers
                      are optional. If a paper has more than 5 authors, a cover
                      letter detailing the contributions of all authors should
                      be included in the appropriate box on the submission page.
                      Only those involved in creating the submission should be
                      included in the author line. Others should be listed as a
                      footnote or acknowledgment. While there is no limit on the
                      number of authors, no more than 12 will be listed on the
                      masthead of the published article; additional authors will
                      be listed at the end of the article. Authorship should be
                      determined using the{' '}
                      <a href="http://www.icmje.org/recommendations/browse/roles-and-responsibilities/defining-the-role-of-authors-and-contributors.html">
                        ICMJE authorship guidelines
                      </a>
                      .
                    </li>
                  </ol>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.nestedAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: '800' }}
                >
                  2.5 Manuscript Format
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="p">
                  Manuscript pages, limited to the abstract, video transcript,
                  and references, should be double- spaced with consecutive page
                  numbers and continuous line numbers. The editorial system
                  handles most common word processing formats; however,
                  Microsoft Word is preferred. The transcript should be in
                  paragraph format and should not include any headings or
                  subheadings. References must be placed at the end of the
                  transcript text.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion className={classes.nestedAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  component="h5"
                  variant="h5"
                  style={{ fontWeight: '800' }}
                >
                  2.6 Manuscript Preparation
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Accordion className={classes.nestedAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      component="h6"
                      variant="h6"
                      style={{ fontWeight: '800' }}
                    >
                      2.6.1 Abstract
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography component="p">
                      <ul style={{ lineHeight: '200%' }}>
                        <li>
                          Background: In one or two sentences, summarize the
                          scientific body of knowledge surrounding your study
                          and how this led to your investigation.
                        </li>
                        <li>
                          Indications: State the reason(s) behind the
                          intervention shown.
                        </li>
                        <li>
                          Technique Description: Provide a concise overview of
                          the surgical or rehabilitation technique shown, as
                          applicable.
                        </li>
                        <li>
                          Results: Briefly summarize the expected outcome of the
                          procedure being described based upon the authors’
                          experience and/or salient literature.
                        </li>
                        <li>
                          Discussion/Conclusion: Summarize the most important
                          conclusions or recommendations that can be directly
                          drawn from your study.
                        </li>
                        <li>
                          Keywords: Include at least 5 keywords for indexing.
                        </li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              3. Submission of the Video and accompanying documents
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              <b>
                Submitting video and documents to JTIPs
                <br />
                <br />
                All photographs or videos of patients or other individuals that
                disclose their identity or any other identifiable information
                must be accompanied by a signed photographic release granting
                permission for their likeness to be reproduced in the article.
              </b>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              4. Accepted Submissions
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              Once an article is accepted and typeset, authors will be required
              to carefully read and correct their manuscript proofs that have
              been copyedited by the publisher. Any extensive changes made by
              authors on the proofs will be charged to authors at the rate of $2
              a line. Authors are responsible for ordering reprints of their
              articles. Completed articles will be published on our website. No
              corrections can be made after publication, including corrections
              to author names and affiliations. All corrections must be made at
              the proofing stage.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              5. Important Notice on Plagiarism and Copyright Infringement
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              <b>JTIPs</b> and PCP Publishing LLC take issues of copyright
              infringement, plagiarism, or other breaches of best practice in
              publication very seriously. We seek to protect the rights of our
              authors and we always investigate claims of plagiarism or misuse
              of articles published in the Journal. Equally, we seek to protect
              the reputation of the Journal. Submitted articles may be checked
              using duplication- checking software. Where an article is found to
              have plagiarized other work or included third party copyright
              material without permission or with insufficient acknowledgment,
              or where authorship of the article is contested, or where data or
              other information found to be incorrect or falsified, we reserve
              the right to take action including, but not limited to: publishing
              an erratum or corrigendum (correction), retracting the article,
              taking up the matter with the head of department or dean of the
              author’s institution and/or relevant academic bodies or societies,
              banning the author from publication in the Journal or appropriate
              legal action.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className={classes.mainAccordion + classes.marginBottom}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              6. Legal:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              Experimentation in Human Subjects, Patient Consent, and Internal
              Review Board Approvals/Waivers <br />
              <br />
              Papers reporting human experimentation will be reviewed in
              accordance with the precepts established by the{' '}
              <a
                href="https://www.wma.net/policies-post/wma-
declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/"
              >
                Declaration of Helsinki (2013)
              </a>
              .<br />
              <br />
              Papers containing studies performed on human subjects must include
              a statement that the investigations were performed after approval
              by a local Human Investigations Committee or Institutional Review
              Board, and after obtaining informed consent from a patient (or
              other responsible individuals). If it is deemed necessary during
              the peer review process, the editors may require that the authors
              provide the editorial office with copies of such documentation. It
              is required that the author(s) retain and archive all relevant
              forms, releases, statements, and consents. <br />
              <br />
              The anonymity of patients, healthy volunteers, and other study
              participants is paramount and must be protected as required by
              law. In most cases, a general description of the study
              participants is sufficient insofar as meaning is not lost.
              Nonessential data (age, sex, occupation, etc.) of individual
              subjects should not be included in a manuscript unless it is
              clinically or epidemiologically relevant or important to the
              manuscript. <br />
              <br />
              When reporting research involving human data, authors should
              indicate whether the procedures followed have been assessed by the
              responsible review committee (institutional and national), or if
              no formal ethics committee is available, were in accordance with
              the{' '}
              <a
                href="https://www.wma.net/policies-post/wma-
declaration-of-helsinki-ethical-principles-for-medical-research-involving-human-subjects/"
              >
                Declaration of Helsinki as revised in 2013
              </a>
              . If doubt exists whether the research was conducted in accordance
              with the Helsinki Declaration, the authors must explain the
              rationale for their approach and demonstrate that the
              institutional review body explicitly approved the doubtful aspects
              of the study. Approval by a responsible review committee does not
              preclude editors from forming their own judgment whether the
              conduct of the research was appropriate. <br />
              <br />
              A sentence declaring adherence to Institutional Review Board
              protocols and informed consent should be included in the
              submission. If the study is judged exempt from approval from an
              Institutional Review Board, a statement from the committee is
              required indicating a waiver has been issued. Informed consent by
              participants should always be secured. If securing consent is not
              possible or waived by an institutional review board, a statement
              and evidence to this effect must be noted by the authors in the
              submission. The information on ethics committee review and
              approval, and securing of informed consent from the study
              participants should also be outlined in the cover letter
              accompanying the submission. <br />
              <br />
              The participant confidentiality and consent requirements contained
              herein are applicable to any text, photograph, image, video, or
              any other media submitted to <b>JTIPs</b>. <br />
              <br />
              Animal Experimentation <br />
              <br />
              Authors must clearly state in the text that the experiments were
              carried out in compliance with the relevant local institutionally
              reviewed animal use regulations and/or national laws relating to
              the conduct of animal experimentation, or, where such laws do not
              exist, that the studies were performed in accordance with an
              internationally recognized code of practice. Authors should
              clearly state source(s) and numbers of animals being used in all
              experiments in their manuscript. <br />
              <br />
              Copyright <br />
              <br />
              Videos previously presented at meetings will be considered as long
              as the authors have retained ownership of their copyright at the
              time of submission. Copyrighted background music cannot be used in
              the video without permission from the copyright owners. All
              accepted videos will become the property of JTIPs. However, after
              being published in JTIPs, the videos may be presented and viewed
              at scientific meetings. <br />
              <br />
              The copyright agreement process between the author and the
              Publisher is handled via email when the video is accepted for
              publication. Authors will receive a follow-up email with
              instructions on how to complete the online Copyright Agreement
              form. <br />
              <br />
              The corresponding author is responsible for communicating with
              coauthors to ensure they have completed the online copyright form.
              Authors not permitted to release copyright must still return the
              form acknowledging the statement of the reason for not releasing
              the copyright. <br />
              <br />
              Permissions for Use of Republished Material <br />
              <br />
              The author is responsible for obtaining permission to reproduce
              figures, tables, and text from previously published materials,
              even if it is the author’s own work. Written permission must be
              obtained from the original copyright holder (generally the
              publisher, the author, or editor) of the journal, book, or video
              concerned. An appropriate credit line should be included in the
              figure legend or table footnote, and full publication information
              should be included in the reference list. Written permission must
              be obtained from the author of any unpublished material cited from
              other institutions and should accompany the submission. <br />
              <br />
              <b>Author Disclosure Statement(s)</b> <br />
              <br />
              When uploading your video submission, you will be asked to
              disclose any commercial associations that might create a conflict
              of interest in connection with submitted manuscripts. This Author
              Disclosure statement should include appropriate information for
              EACH author, thereby representing that competing financial
              interests of all authors have been appropriately disclosed
              according to the policy of the Journal. It is important that all
              information will remain confidential while the video is being
              reviewed and will not influence the editorial decision. <br />
              <br />
              If no conflicts exist, the authors must state “No competing
              financial interests exist.” <br />
              <br />
              <b>Music</b> <br />
              <br />
              If music is used in your video, please provide the source of the
              music, and whether or not it is copyrighted material. Copyrighted
              music cannot be used in the video without permission from the
              copyright owners. Written permission from the copyright holder
              must be supplied by the submitting author at the time of
              submission. There are several websites that offer royalty-free
              music and are listed below. Note that these are third-party sites
              and the Publisher does not endorse and/or support any of these
              sites in any capacity:
              <ul>
                <li>
                  <a href="https://stockmusic.net/">www.stockmusic.net</a>
                </li>
                <li>
                  <a href="http://freeplaymusic.com">
                    http://freeplaymusic.com
                  </a>
                </li>
                <li>
                  <a href="http://incompetech.com/music/royalty-free">
                    http://incompetech.com/music/royalty-free
                  </a>
                </li>
                <li>
                  <a href="https://www.premiumbeat.com/">www.premiumbeat.com</a>
                </li>
                <li>
                  <a href="https://www.royaltyfreemusicweb.com/">
                    www.royaltyfreemusicweb.com
                  </a>
                </li>
              </ul>
              <b>Reprints (Copies of Videos)</b> <br />
              <br />
              After the video is published it may not be published elsewhere
              without explicit permission from the Publisher. While personal
              copies of the video can be downloaded and saved for personal use
              only, any systematic download that would create a new database
              will not be allowed.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
      <Footer />
    </Wrapper>
  )
}

export default AboutUsPage

const Wrapper = styled.div``

const Row = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
  align-items: center;
`
const p1 = {
  width: '70%',
}
