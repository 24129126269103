import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ArticleCard from './ArticleCard'
import ArticleService from '../services/ArticleService'

const ArticleList = ({
  articles,
  setArticles,
  setArticlesCopy,
  isBrowseBy,
}) => {
  const [page, setPage] = useState(8)
  const [articlesNumber, setArticlesNumber] = useState(0)
  const [articlesSize, setArticlesSize] = useState(0)

  useEffect(async () => {
    await ArticleService.getArticles(page).then((data) => {
      setArticles(data)
      setArticlesCopy(data)
      setArticlesNumber(articlesNumber + page)
    })
    await ArticleService.getArticles().then((data) => {
      setArticlesSize(data.length)
    })
  }, [page])

  return (
    <Wrapper>
      <VideosContainer>
        {articles &&
          articles.map((article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
      </VideosContainer>
      {!isBrowseBy &&
        articles.length === 8 &&
        articlesNumber !== articlesSize && (
          <LoadMore onClick={() => setPage(page + 8)}>Load More</LoadMore>
        )}
    </Wrapper>
  )
}

export default ArticleList

const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 20px 0 60px 0;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const VideosContainer = styled.div`
  box-sizing: border-box;
  min-height: calc(100vh - 299px);
  display: grid;
  grid-template-columns: 320px 320px 320px 320px;
  column-gap: 1rem;
  row-gap: 2rem;
  margin: 0 auto;
  justify-content: center;
  @media only screen and (max-width: 1400px) {
    grid-template-columns: 320px 320px 320px;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (min-width: 767px) and (max-width: 1025px) {
    grid-template-columns: 1fr 1fr;
  }
`
const LoadMore = styled.button`
  margin: 40px 0 0 10px;
  width: 200px;
  padding: 8px 0;
  border: 2px solid #55bbf1;
  background-color: #f4f4f4;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.15s ease-in;
  &:hover {
    background: #55bbf1;
    color: white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  }
`
