class UserHelper {
  static getInitials(user) {
    return (
      user.first_name.toUpperCase().substr(0, 1) +
      user.last_name.substr(0, 1).toUpperCase()
    )
  }

  static getFullName(user) {
    return `${
      user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
    } ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1)}`
  }
}

export default UserHelper
