import React, { useState } from 'react'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { Redirect } from 'react-router-dom'
import AdminPageDrawer from '../components/Drawer'

export const ReviewerPanel = () => {
  let role = null
  if (localStorage.getItem('token'))
    role = jwt_decode(localStorage.getItem('token')).user.role
  const [isReviewer] = useState(role === 2)

  return <>{isReviewer ? <AdminPageDrawer /> : <Redirect to="/" />}</>
}
