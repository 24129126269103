import React from 'react'
import styled from 'styled-components'
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const useStyles = makeStyles(() => ({
  mainAccordion: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  nestedAccordion: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  accordionDetailsFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom: {
    marginBottom: '45px',
  },
}))

const PrivacyPage = () => {
  const classes = useStyles()

  return (
    <Wrapper>
      <Navbar />
      <Container maxWidth="lg">
        <Row>
          <Typography component="h3" variant="h3" style={{ fontWeight: '800' }}>
            PRIVACY NOTICE
            <Typography component="p" style={{ fontWeight: '800' }}>
              Last updated June 10, 2021
            </Typography>
          </Typography>
        </Row>
        <Row>
          <Typography component="p" style={p1}>
            Thank you for choosing to be part of our community at Journal of
            Techniques and Innovations in Pediatrics, doing business as JTIPs
            (&quot;JTIPs&quot;, &quot;we&quot;, &quot;us&quot;,
            &quot;our&quot;). We are committed to protecting your personal
            information and your right to privacy. If you have any questions or
            concerns about this privacy notice, or our practices with regards to
            your personal information, please contact us at j.of.tips@gmail.com.
          </Typography>
        </Row>
        <Row>
          <Typography component="p" style={p1}>
            When you visit our website http://www.jtips.org (the
            &quot;Website&quot;), and more generally, use any of our services
            (the &quot;Services&quot;, which include the Website), we appreciate
            that you are trusting us with your personal information. We take
            your privacy very seriously. In this privacy notice, we seek to
            explain to you in the clearest way possible what information we
            collect, how we use it and what rights you have in relation to it.
            We hope you take some time to read through it carefully, as it is
            important. If there are any terms in this privacy notice that you do
            not agree with, please discontinue use of our Services immediately.
          </Typography>
        </Row>
        <Row>
          <Typography component="p" style={p1}>
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as, any related services, sales, marketing or events.
          </Typography>
        </Row>
        <Row>
          <Typography component="p" style={{ fontWeight: '800' }}>
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </Typography>
        </Row>
        <Row>
          <Typography component="p">
            All submissions must be in English. Appropriate use of English is a
            requirement for review and publication in <b>JTIPs</b>. For
            non-native English speakers, we encourage you to use widely
            available translation services. Please note that employing the use
            of such a service is not mandatory and using it, or any other
            language editing service does not guarantee the acceptance of any
            paper. All submissions are subject to peer review.
          </Typography>
        </Row>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              TABLE OF CONTENTS
            </Typography>
          </AccordionSummary>
          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                1. WHAT INFORMATION DO WE COLLECT?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                Personal information you disclose to us
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    In Short: We collect personal information that you provide
                    to us.
                  </li>
                  <li>
                    We collect personal information that you voluntarily provide
                    to us when you express an interest in obtaining information
                    about us or our products and Services, when you participate
                    in activities on the Website (such as by posting messages in
                    our online forums or entering competitions, contests or
                    giveaways) or otherwise when you contact us.
                  </li>
                  <li>
                    The personal information that we collect depends on the
                    context of your interactions with us and the Website, the
                    choices you make and the products and features you use. The
                    personal information we collect may include the following:
                  </li>
                  <li>
                    Personal Information Provided by You. We collect names;
                    email addresses; job titles; mailing addresses; contact
                    preferences; phone numbers; institution; and other similar
                    information.
                  </li>
                  <li>
                    Social Media Login Data. We may provide you with the option
                    to register with us using your existing social media account
                    details, like your Facebook, Twitter or other social media
                    account. If you choose to register in this way, we will
                    collect the information described in the section called
                    &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS?&quot; below.
                  </li>
                  <li>
                    All personal information that you provide to us must be
                    true, complete and accurate, and you must notify us of any
                    changes to such personal information.
                  </li>
                  <li>Information automatically collected</li>
                  <li>
                    In Short: Some information — such as your Internet Protocol
                    (IP) address and/or browser and device characteristics — is
                    collected automatically when you visit our Website.
                  </li>
                  <li>
                    We automatically collect certain information when you visit,
                    use or navigate the Website. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Website and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Website, and for our internal
                    analytics and reporting purposes.
                  </li>
                  <li>
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                  </li>
                  <li>
                    <ul>
                      The information we collect includes:
                      <li>
                        Log and Usage Data. Log and usage data is
                        service-related, diagnostic, usage and performance
                        information our servers automatically collect when you
                        access or use our Website and which we record in log
                        files. Depending on how you interact with us, this log
                        data may include your IP address, device information,
                        browser type and settings and information about your
                        activity in the Website (such as the date/time stamps
                        associated with your usage, pages and files viewed,
                        searches and other actions you take such as which
                        features you use), device event information (such as
                        system activity, error reports (sometimes called
                        &apos;crash dumps&apos;) and hardware settings).
                      </li>
                      <li>
                        Location Data. We collect location data such as
                        information about your device&apos;s location, which can
                        be either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Website. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        Note however, if you choose to opt out, you may not be
                        able to use certain aspects of the Services.
                      </li>
                    </ul>
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                2. HOW DO WE USE YOUR INFORMATION?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We process your information for purposes based on
                legitimate business interests, the fulfillment of our contract
                with you, compliance with our legal obligations, and/or your
                consent.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We use personal information collected via our Website for a
                    variety of business purposes described below. We process
                    your personal information for these purposes in reliance on
                    our legitimate business interests, in order to enter into or
                    perform a contract with you, with your consent, and/or for
                    compliance with our legal obligations. We indicate the
                    specific processing grounds we rely on next to each purpose
                    listed below.
                  </li>
                  <li>We use the information we collect or receive:</li>
                  <li>
                    To facilitate account creation and logon process. If you
                    choose to link your account with us to a third-party account
                    (such as your Google or Facebook account), we use the
                    information you allowed us to collect from those third
                    parties to facilitate account creation and logon process for
                    the performance of the contract. See the section below
                    headed &quot;HOW DO WE HANDLE YOUR SOCIAL LOGINS?&quot; for
                    further information.
                  </li>
                  <li>
                    To post testimonials. We post testimonials on our Website
                    that may contain personal information. Prior to posting a
                    testimonial, we will obtain your consent to use your name
                    and the content of the testimonial. If you wish to update,
                    or delete your testimonial, please contact us at
                    j.of.tips@gmail.com and be sure to include your name,
                    testimonial location, and contact information.
                  </li>
                  <li>
                    Request feedback. We may use your information to request
                    feedback and to contact you about your use of our Website.
                  </li>
                  <li>
                    To enable user-to-user communications. We may use your
                    information in order to enable user-to-user communications
                    with each user&apos;s consent.
                  </li>
                  <li>
                    To manage user accounts. We may use your information for the
                    purposes of managing our account and keeping it in working
                    order.
                  </li>
                  <li>
                    To send administrative information to you. We may use your
                    personal information to send you product, service and new
                    feature information and/or information about changes to our
                    terms, conditions, and policies.
                  </li>
                  <li>
                    To protect our Services. We may use your information as part
                    of our efforts to keep our Website safe and secure (for
                    example, for fraud monitoring and prevention).
                  </li>
                  <li>
                    To enforce our terms, conditions and policies for business
                    purposes, to comply with legal and regulatory requirements
                    or in connection with our contract.
                  </li>
                  <li>
                    To respond to legal requests and prevent harm. If we receive
                    a subpoena or other legal request, we may need to inspect
                    the data we hold to determine how to respond.
                  </li>
                  <li>
                    To send you marketing and promotional communications. We
                    and/or our third-party marketing partners may use the
                    personal information you send to us for our marketing
                    purposes, if this is in accordance with your marketing
                    preferences. For example, when expressing an interest in
                    obtaining information about us or our Website, subscribing
                    to marketing or otherwise contacting us, we will collect
                    personal information from you. You can opt-out of our
                    marketing emails at any time (see the &quot;WHAT ARE YOUR
                    PRIVACY RIGHTS?&quot; below).
                  </li>
                  <li>
                    Deliver targeted advertising to you. We may use your
                    information to develop and display personalized content and
                    advertising (and work with third parties who do so) tailored
                    to your interests and/or location and to measure its
                    effectiveness.
                  </li>
                  <li>
                    For other business purposes. We may use your information for
                    other business purposes, such as data analysis, identifying
                    usage trends, determining the effectiveness of our
                    promotional campaigns and to evaluate and improve our
                    Website, products, marketing and your experience. We may use
                    and store this information in aggregated and anonymized form
                    so that it is not associated with individual end users and
                    does not include personal information. We will not use
                    identifiable personal information without your consent.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We only share information with your consent, to comply
                with laws, to provide you with services, to protect your rights,
                or to fulfill business obligations.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We may process or share your data that we hold based on the
                    following legal basis:
                  </li>
                  <li>
                    Consent: We may process your data if you have given us
                    specific consent to use your personal information for a
                    specific purpose.
                  </li>
                  <li>
                    Legitimate Interests: We may process your data when it is
                    reasonably necessary to achieve our legitimate business
                    interests.
                  </li>
                  <li>
                    Vital Interests: We may disclose your information where we
                    believe it is necessary to investigate, prevent, or take
                    action regarding potential violations of our policies,
                    suspected fraud, situations involving potential threats to
                    the safety of any person and illegal activities, or as
                    evidence in litigation in which we are involved.
                  </li>
                  <li>
                    More specifically, we may need to process your data or share
                    your personal information in the following situations:
                  </li>
                  <li>
                    Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </li>
                  <li>
                    Google Maps Platform APIs. We may share your information
                    with certain Google Maps Platform APIs (e.g., Google Maps
                    API, Place API). To find out more about Google’s Privacy
                    Policy, please refer to this link.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We may use cookies and other tracking technologies to
                collect and store your information.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: If you choose to register or log in to our services
                using a social media account, we may have access to certain
                information about you.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    Our Website offers you the ability to register and login
                    using your third-party social media account details (like
                    your Facebook or Twitter logins). Where you choose to do
                    this, we will receive certain profile information about you
                    from your social media provider. The profile information we
                    receive may vary depending on the social media provider
                    concerned, but will often include your name, email address,
                    friends list, profile picture as well as other information
                    you choose to make public on such social media platform.
                  </li>
                  <li>
                    We will use the information we receive only for the purposes
                    that are described in this privacy notice or that are
                    otherwise made clear to you on the relevant Website. Please
                    note that we do not control, and are not responsible for,
                    other uses of your personal information by your third-party
                    social media provider. We recommend that you review their
                    privacy notice to understand how they collect, use and share
                    your personal information, and how you can set your privacy
                    preferences on their sites and apps.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We are not responsible for the safety of any
                information that you share with third-party providers who
                advertise, but are not affiliated with, our Website.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    The Website may contain advertisements from third parties
                    that are not affiliated with us and which may link to other
                    websites, online services or mobile applications. We cannot
                    guarantee the safety and privacy of data you provide to any
                    third parties. Any data collected by third parties is not
                    covered by this privacy notice. We are not responsible for
                    the content or privacy and security practices and policies
                    of any third parties, including other websites, services or
                    applications that may be linked to or from the Website. You
                    should review the policies of such third parties and contact
                    them directly to respond to your questions.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                7. HOW LONG DO WE KEEP YOUR INFORMATION?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice, unless a longer retention period is required or
                    permitted by law (such as tax, accounting or other legal
                    requirements). No purpose in this notice will require us
                    keeping your personal information for longer than 2 years.
                  </li>
                  <li>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize such information, or, if this is not possible (for
                    example, because your personal information has been stored
                    in backup archives), then we will securely store your
                    personal information and isolate it from any further
                    processing until deletion is possible.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                8. HOW DO WE KEEP YOUR INFORMATION SAFE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We have implemented appropriate technical and organizational
                    security measures designed to protect the security of any
                    personal information we process. However, despite our
                    safeguards and efforts to secure your information, no
                    electronic transmission over the Internet or information
                    storage technology can be guaranteed to be 100% secure, so
                    we cannot promise or guarantee that hackers, cybercriminals,
                    or other unauthorized third parties will not be able to
                    defeat our security, and improperly collect, access, steal,
                    or modify your information. Although we will do our best to
                    protect your personal information, transmission of personal
                    information to and from our Website is at your own risk. You
                    should only access the Website within a secure environment.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                9. DO WE COLLECT INFORMATION FROM MINORS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: We do not knowingly collect data from or market to
                children under 18 years of age.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By using the Website, you represent
                    that you are at least 18 or that you are the parent or
                    guardian of such a minor and consent to such minor
                    dependent’s use of the Website. If we learn that personal
                    information from users less than 18 years of age has been
                    collected, we will deactivate the account and take
                    reasonable measures to promptly delete such data from our
                    records. If you become aware of any data we may have
                    collected from children under age 18, please contact us at
                    j.of.tips@gmail.com.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                10. WHAT ARE YOUR PRIVACY RIGHTS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: In some regions, such as the European Economic Area
                (EEA) and United Kingdom (UK), you have rights that allow you
                greater access to and control over your personal information.
                You may review, change, or terminate your account at any time.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    In some regions (like the EEA and UK), you have certain
                    rights under applicable data protection laws. These may
                    include the right (i) to request access and obtain a copy of
                    your personal information, (ii) to request rectification or
                    erasure; (iii) to restrict the processing of your personal
                    information; and (iv) if applicable, to data portability. In
                    certain circumstances, you may also have the right to object
                    to the processing of your personal information. To make such
                    a request, please use the contact details provided below. We
                    will consider and act upon any request in accordance with
                    applicable data protection laws.
                  </li>
                  <li>
                    If we are relying on your consent to process your personal
                    information, you have the right to withdraw your consent at
                    any time. Please note however that this will not affect the
                    lawfulness of the processing before its withdrawal, nor will
                    it affect the processing of your personal information
                    conducted in reliance on lawful processing grounds other
                    than consent. If you are a resident in the EEA or UK and you
                    believe we are unlawfully processing your personal
                    information, you also have the right to complain to your
                    local data protection supervisory authority. You can find
                    their contact details here:
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                  </li>
                  <li>
                    If you are a resident in Switzerland, the contact details
                    for the data protection authorities are available here:
                    https://www.edoeb.admin.ch/edoeb/en/home.html.d act upon any
                    request in accordance with applicable data protection laws.
                  </li>
                  <li>
                    Cookies and similar technologies: Most Web browsers are set
                    to accept cookies by default. If you prefer, you can usually
                    choose to set your browser to remove cookies and to reject
                    cookies. If you choose to remove cookies or reject cookies,
                    this could affect certain features or services of our
                    Website. To opt-out of interest-based advertising by
                    advertisers on our Website visit
                    http://www.aboutads.info/choices/.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                11. CONTROLS FOR DO-NOT-TRACK FEATURES
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track (&quot;DNT&quot;)
                    feature or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform
                    technology standard for recognizing and implementing DNT
                    signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: Yes, if you are a resident of California, you are
                granted specific rights regarding access to your personal
                information.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    California Civil Code Section 1798.83, also known as the
                    &quot;Shine The Light&quot; law, permits our users who are
                    California residents to request and obtain from us, once a
                    year and free of charge, information about categories of
                    personal information (if any) we disclosed to third parties
                    for direct marketing purposes and the names and addresses of
                    all third parties with which we shared personal information
                    in the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </li>
                  <li>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with the Website, you have the
                    right to request removal of unwanted data that you publicly
                    post on the Website. To request removal of such data, please
                    contact us using the contact information provided below, and
                    include the email address associated with your account and a
                    statement that you reside in California. We will make sure
                    the data is not publicly displayed on the Website, but
                    please be aware that the data may not be completely or
                    comprehensively removed from all our systems (e.g. backups,
                    etc.).
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                13. DO WE MAKE UPDATES TO THIS NOTICE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                In Short: Yes, we will update this notice as necessary to stay
                compliant with relevant laws.
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an updated
                    &quot;Revised&quot; date and the updated version will be
                    effective as soon as it is accessible. If we make material
                    changes to this privacy notice, we may notify you either by
                    prominently posting a notice of such changes or by directly
                    sending you a notification. We encourage you to review this
                    privacy notice frequently to be informed of how we are
                    protecting your information.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                If you have questions or comments about this notice, you may
                email us at j.of.tips@gmail.com or by post to:
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    Journal of Techniques and Innovations in Pediatrics 1810
                    <br />
                    East Sahara Ave Suite 212 # 770 Las Vegas, NV 89104
                    <br />
                    United States
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className={classes.nestedAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                component="h5"
                variant="h5"
                style={{ fontWeight: '800' }}
              >
                15. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                FROM YOU?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="p">
                <ul style={{ lineHeight: '200%' }}>
                  <li>
                    Based on the applicable laws of your country, you may have
                    the right to request access to the personal information we
                    collect from you, change that information, or delete it in
                    some circumstances. To request to review, update, or delete
                    your personal information, please submit a request form by
                    clicking here.
                  </li>
                  <li>
                    This privacy policy was created using Termly’s Privacy
                    Policy Generator.
                  </li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Accordion>
      </Container>
      <Footer />
    </Wrapper>
  )
}

export default PrivacyPage

const Wrapper = styled.div``

const Row = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
  align-items: center;
`
const p1 = {
  width: '70%',
}
