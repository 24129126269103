import React from 'react'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import { CardContent, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import UserHelper from '../helpers/UserHelper'

const ArticleCard = ({ article }) => {
  const videoUrl = `https://player.vimeo.com/video/${article.video_url}`
  const history = useHistory()
  const classes = useStyles()

  return (
    <Card
      classes={{ root: classes.container }}
      onClick={() => {
        history.push(`/article/${article.id}`)
      }}
      style={{ cursor: 'pointer' }}
    >
      <CardHeader
        avatar={<Avatar>{UserHelper.getInitials(article.user)}</Avatar>}
        title={UserHelper.getFullName(article.user)}
        subheader={article.date.slice(0, 10)}
      />
      <iframe
        src={videoUrl}
        width="100%"
        frameBorder="0"
        allowFullScreen
        title="video"
        style={{ backgroundColor: '#000000' }}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {article.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {article.short_description}
        </Typography>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    width: 300,
    height: '100%',
    boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
  },
}))

export default ArticleCard
