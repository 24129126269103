/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
// import ListItemAvatar from '@material-ui/core/ListItemAvatar'
// import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ArticleService from '../services/ArticleService'
import AdminPanelContext from '../context/AdminPanelContext'

const useStyles = makeStyles(() => ({
  articleText: {
    margin: '10px 10px',
  },
  buttonMargin: {
    marginLeft: '5px',
  },
}))

const ManageArticlesList = ({ articles, selectedTab, fetchArticles }) => {
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [openApproveDisapprovePopup, setOpenApproveDisapprovePopup] =
    useState(false)
  const [openDeletePopup, setOpenDeletePopup] = useState(false)
  const videoUrl = `https://player.vimeo.com/video/`
  const classes = useStyles()
  const context = React.useContext(AdminPanelContext)
  const history = useHistory()

  const handleClose = () => {
    if (openApproveDisapprovePopup) setOpenApproveDisapprovePopup(false)
    if (openDeletePopup) setOpenDeletePopup(false)
  }

  const handleApprove = async () => {
    await ArticleService.approveArticle({
      ...selectedArticle,
      approved: true,
    }).then(async () => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      } else {
        await fetchArticles()
        setOpenApproveDisapprovePopup(false)
      }
    })
  }

  const handleDisapprove = async () => {
    await ArticleService.approveArticle({
      ...selectedArticle,
      approved: false,
    }).then(async () => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      } else {
        await fetchArticles()
        setOpenApproveDisapprovePopup(false)
      }
    })
  }

  const handleDelete = async () => {
    await ArticleService.deleteArticle(selectedArticle.id).then(async () => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      } else {
        await fetchArticles()
        setOpenDeletePopup(false)
      }
    })
    await ArticleService.deleteVideo(selectedArticle.video_url)
  }
  const handleEdit = (article) => {
    context.setEditedArticle(article)
    context.setToEditArticle(true)
    // context.setAdminPage(6)
    history.push('/admin/article')
  }

  return (
    <>
      <Dialog
        open={openApproveDisapprovePopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This article will be{' '}
            {selectedTab === 1 ? 'disapproved' : 'approved'}, are you sure you
            want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Close
          </Button>
          <Button
            onClick={selectedTab === 1 ? handleDisapprove : handleApprove}
            color="default"
            autoFocus
          >
            {selectedTab === 1 ? 'Disapprove' : 'Approve'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeletePopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This article will be deleted, are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Close
          </Button>
          <Button onClick={handleDelete} style={{ color: '#FF0000' }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <List className={classes.list}>
        {articles &&
          articles.map((article) => (
            <>
              <ListItem
                alignItems="flex-start"
                className={classes.li}
                onClick={() => {
                  context.setReviewArticle(1)
                  history.push(`/article/${article.id}`)
                }}
                style={{ cursor: 'pointer' }}
              >
                {/* <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar> */}
                <iframe
                  src={videoUrl + article.video_url}
                  width="{video_width}"
                  height="200px"
                  frameBorder="0"
                  allowFullScreen
                  title="video"
                >
                  Video
                </iframe>
                <ListItemText
                  primary={`${article.title}`}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {article.short_description}
                      </Typography>
                      <br />
                      upload date {article.date.split('T')[0]}
                    </>
                  }
                  className={classes.articleText}
                />
                {(selectedTab === 0 && (
                  <ListItemSecondaryAction>
                    <Button
                      endIcon={<DeleteIcon />}
                      variant="contained"
                      style={{ color: '#FF0000' }}
                      onClick={() => {
                        setSelectedArticle(article)
                        setOpenDeletePopup(true)
                      }}
                    >
                      Delete
                    </Button>
                    <Button
                      endIcon={<EditIcon />}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleEdit(article)
                      }}
                      className={classes.buttonMargin}
                    >
                      Edit
                    </Button>
                  </ListItemSecondaryAction>
                )) ||
                  (selectedTab === 1 && (
                    <ListItemSecondaryAction>
                      <Button
                        endIcon={<HighlightOffIcon />}
                        variant="contained"
                        color="default"
                        onClick={() => {
                          setSelectedArticle(article)
                          setOpenApproveDisapprovePopup(true)
                        }}
                      >
                        Disapprove
                      </Button>
                      <IconButton
                        variant="contained"
                        style={{ color: '#FF0000' }}
                        onClick={() => {
                          setSelectedArticle(article)
                          setOpenDeletePopup(true)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleEdit(article)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )) ||
                  (selectedTab === 2 && (
                    <ListItemSecondaryAction>
                      <Button
                        endIcon={<CheckCircleOutlineIcon />}
                        variant="contained"
                        color="default"
                        onClick={() => {
                          setSelectedArticle(article)
                          setOpenApproveDisapprovePopup(true)
                        }}
                      >
                        Approve
                      </Button>
                      <IconButton
                        variant="contained"
                        style={{ color: '#FF0000' }}
                        onClick={() => {
                          setSelectedArticle(article)
                          setOpenDeletePopup(true)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleEdit(article)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ))}
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
      </List>
    </>
  )
}

export default ManageArticlesList
