import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Footer = () => (
  <Wrapper>
    <P1>Copyright jtips.org 2021</P1>
    <Links>
      <P2>
        <Link to="/about" className="footerLink">
          About Us
        </Link>
      </P2>
      <P2>
        <Link to="/privacy" className="footerLink">
          Privacy
        </Link>
      </P2>
      <P2>
        <Link to="/terms-and-conditions" className="footerLink">
          Terms of use
        </Link>
      </P2>
    </Links>
  </Wrapper>
)

export default Footer

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  padding: 0 5%;
  background-color: black;
  display: flex;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`
const P1 = styled.p`
  color: #8e8e8e;
  font-size: 12px;
`
const Links = styled.div`
  display: flex;
`
const P2 = styled.p`
  color: #8e8e8e;
  font-size: 12px;
  padding: 4px 8px;
  cursor: pointer;
  &:nth-child(1),
  &:nth-child(2) {
    border-right: 1px solid #8e8e8e;
  }
  &:hover {
    color: white;
  }
`
