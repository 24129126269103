/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
// import ListItemAvatar from '@material-ui/core/ListItemAvatar'
// import Avatar from '@material-ui/core/Avatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'
import StarIcon from '@material-ui/icons/Star'
import Typography from '@material-ui/core/Typography'
import AdminPanelContext from '../context/AdminPanelContext'

const useStyles = makeStyles(() => ({
  articleText: {
    margin: '10px 10px',
  },
  buttonMargin: {
    marginLeft: '5px',
  },
}))

const ReviewArticlesList = ({ articles, selectedTab }) => {
  useState(false)
  const videoUrl = `https://player.vimeo.com/video/`
  const classes = useStyles()
  const context = React.useContext(AdminPanelContext)
  const history = useHistory()

  return (
    <>
      <List className={classes.list}>
        {articles &&
          articles.map((article) => (
            <>
              <ListItem
                alignItems="flex-start"
                className={classes.li}
                onClick={() => {
                  history.push(`/article/${article.articleId}`)
                  context.setReviewArticle(1)
                  context.setReviewListID(article.id)
                }}
                style={{ cursor: 'pointer' }}
              >
                {/* <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar> */}
                <iframe
                  src={
                    videoUrl &&
                    videoUrl + article &&
                    article.article &&
                    article.article.video_url
                  }
                  width="{video_width}"
                  height="200px"
                  frameBorder="0"
                  allowFullScreen
                  title="video"
                >
                  Video
                </iframe>
                <ListItemText
                  primary={`${article.article && article.article.title}`}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {article.article && article.article.short_description}
                      </Typography>
                      <br />
                      upload date{' '}
                      {article.article && article.article.date.split('T')[0]}
                    </>
                  }
                  className={classes.articleText}
                />
                {(selectedTab === 0 && (
                  <ListItemSecondaryAction>
                    <Button
                      endIcon={<StarIcon />}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        history.push(`/article/${article.articleId}`)
                        context.setReviewArticle(1)
                        context.setReviewListID(articles.id)
                      }}
                      className={classes.buttonMargin}
                    >
                      Add Review
                    </Button>
                  </ListItemSecondaryAction>
                )) ||
                  (selectedTab === 1 && (
                    <ListItemSecondaryAction>
                      <Button
                        endIcon={<EditIcon />}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          history.push(`/article/${article.articleId}`)
                          context.setReviewArticle(2)
                        }}
                        className={classes.buttonMargin}
                      >
                        Edit Review
                      </Button>
                    </ListItemSecondaryAction>
                  ))}
              </ListItem>
              <Divider variant="inset" component="li" />
            </>
          ))}
      </List>
    </>
  )
}

export default ReviewArticlesList
