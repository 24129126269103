import React, { useState, useEffect } from 'react'

import jwtDecode from 'jwt-decode'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import ReviewArticlesList from './ReviewArticlesList'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    width: '80%',
    minWidth: '736px',
    margin: '0 auto',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={false}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ReviewArticles = () => {
  const [toBeReviewedArticles, setToBeReviewedArticles] = useState([])
  const [reviewedArticles, setReviewedArticles] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  const classes = useStyles()

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const fetchArticles = async () => {
    const decodedID = jwtDecode(localStorage.getItem('token'))

    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': localStorage.getItem('token'),
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviewer-list/users/${decodedID.user.id}`,
      { headers },
    ).then((res) => {
      res.json().then((articles) => {
        setToBeReviewedArticles(articles)
      })
    })
  }

  const fetchReviewdArticles = async () => {
    const decodedID = jwtDecode(localStorage.getItem('token'))
    const headers = {
      'Content-Type': 'application/json',
      'x-auth-token': localStorage.getItem('token'),
    }
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/reviews/users/${decodedID.user.id}`,
      { headers },
    ).then((res) => {
      res.json().then((articles) => {
        setReviewedArticles(articles)
      })
    })
  }

  useEffect(async () => {
    await fetchArticles()
    await fetchReviewdArticles()
  }, [])

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="To be reviewed" {...a11yProps(0)} />
          <Tab label="Reviewed" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={selectedTab} index={0}>
        <ReviewArticlesList
          articles={toBeReviewedArticles}
          selectedTab={selectedTab}
          fetchArticles={fetchArticles}
        ></ReviewArticlesList>
      </TabPanel>

      <TabPanel value={selectedTab} index={1}>
        <ReviewArticlesList
          articles={reviewedArticles}
          selectedTab={selectedTab}
          fetchReviewdArticles={fetchReviewdArticles}
        ></ReviewArticlesList>
      </TabPanel>
    </div>
  )
}

export default ReviewArticles
