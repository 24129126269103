import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'

const EmailDecline = () => {
  const [tokenExpired, setTokenExpired] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const handleDeclineReview = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/email/${
        location.pathname.split('/')[2]
      }`,
      {
        method: 'PATCH',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ role: 3 }),
      },
    ).then(async (response) => {
      const id = location.pathname.split('/')[2]
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/invite/reviewer/${id}`,
        {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      ).then(() => {
        history.push('/login')
      })
      response.json()
    })
  }

  const handleDeleteInvite = async (event) => {
    const id = location.pathname.split('/')[2]

    event.preventDefault()

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/invite/reviewer/${id}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(() => {
      history.push('/')
    })
  }
  useEffect(async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/invite/reviewer/${
        location.pathname.split('/')[2]
      }`,
    ).then(async (res) => {
      const response = await res.json()

      if (response.message !== 'ok') setTokenExpired(true)
    })
  }, [])

  return (
    <div
      style={{
        textAlign: 'center',
        margin: '3em 1em',
      }}
    >
      <h2>
        Are you sure you want to decline the reviewer invite for Journal of
        Techniques and Innovations in Pediatrics?
      </h2>
      {tokenExpired ? (
        <div>
          <Button
            disabled
            variant="contained"
            color="secondary"
            style={{ marginTop: '2em' }}
          >
            Decline the reviewer role
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteInvite}
            style={{ marginTop: '2em' }}
          >
            Redirect to home
          </Button>
          <h3 style={{ color: '#cc0000', marginTop: '2em' }}>
            We are sorry, 14 (or more) days have passed and your token for
            declining the review has expired.
          </h3>
        </div>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeclineReview}
          style={{ marginTop: '2em' }}
        >
          Decline the reviewer role
        </Button>
      )}
    </div>
  )
}
export default EmailDecline
