import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import Footer from './Footer'
import Navbar from './Navbar'
import ArticleList from './ArticleList'

const ArticlesBrowseBy = (props) => {
  // eslint-disable-next-line
  const [articles, setArticles] = useState([])
  const [articlesCopy, setArticlesCopy] = useState([])
  const [categories, setCategories] = useState([])
  const years = [2021, 2022, 2023, 2024]
  const classes = useStyles()
  const browseByYear = props.location.state
    ? props.location.state.browseByYear
    : null

  const getCategories = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data)
      })
  }

  const filterByCategory = (categoryFilter) => {
    let filteredArticles = []
    filteredArticles = articlesCopy.filter(
      (item) => item.categoryId === categoryFilter,
    )
    return filteredArticles
  }

  const filterByYear = (yearFilter) => {
    let filteredArticles = []
    filteredArticles = articlesCopy.filter(
      (item) => item.date.slice(0, 4) === yearFilter.toString(),
    )
    return filteredArticles
  }

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <>
      <Wrapper>
        <Navbar setArticles={setArticles} articlesCopy={articlesCopy} />
        <Container maxWidth="lg" className={classes.container}>
          {browseByYear
            ? years.map((item) => (
                <Accordion className={classes.mainAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      style={{ fontWeight: '800' }}
                    >
                      {item}.
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ArticleList
                      articles={filterByYear(item)}
                      setArticles={setArticles}
                      setArticlesCopy={setArticlesCopy}
                      isBrowseBy
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            : categories &&
              categories.map((item) => (
                <Accordion className={classes.mainAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      style={{ fontWeight: '800' }}
                    >
                      {item.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ArticleList
                      articles={filterByCategory(item.id)}
                      setArticles={setArticles}
                      setArticlesCopy={setArticlesCopy}
                      isBrowseBy
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(() => ({
  mainAccordion: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  container: {
    marginTop: '1em',
    marginBottom: '1em',
  },
}))

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export default ArticlesBrowseBy
