import { NotificationManager } from 'react-notifications'

class CommentService {
  //  static BACKEND_URL = 'http://procerasoft.com:5002'

  static async addComment(data) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comments`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json()
      if (response.status === 201) {
        NotificationManager.success('Comment Added Successfully')
      } else if (response.status === 401) {
        try {
          localStorage.removeItem('token')
        } catch (error) {
          NotificationManager.success(error)
        }
      }
    })
  }

  static async deleteComment(data) {
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comments/${data}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((response) => {
      response.json()
      if (response.status === 200) {
        NotificationManager.success('Comment Deleted Successfully')
      } else if (response.status === 401) {
        try {
          localStorage.removeItem('token')
        } catch (error) {
          NotificationManager.success(error)
        }
      } else {
        NotificationManager.error('Error deleting comment!', '', 400)
      }
    })
  }
}

export default CommentService
