import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ArticleList from '../components/ArticleList'
import AdminPanelContext from '../context/AdminPanelContext'

const ArticlesPage = () => {
  const context = React.useContext(AdminPanelContext)
  const [articles, setArticles] = useState([])
  const [articlesCopy, setArticlesCopy] = useState([])
  // const [articlesFilteredCopy, setArticlesFilteredCopy] = useState([])
  const classes = useStyles()
  // const [category, setCategory] = useState(-1)
  const [categories, setCategories] = useState([])
  // const [year, setYear] = useState(-1)

  const handleCategoryChange = (event) => {
    context.setFilters({ ...context.filters, category: event.target.value })
  }

  const handleYearChange = (event) => {
    context.setFilters({ ...context.filters, year: event.target.value })
  }

  const getCategories = async () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/categories`)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data)
      })
  }

  const filter = () => {
    const filteredArticles = articlesCopy.filter((article) => {
      let result = true
      let resultForTitle = true
      let resultForKeywords = true
      if (context.filters.search !== '') {
        const titleInLowerCase = article.title.toLowerCase()
        const searchQueryInLowerCase = context.filters.search.toLowerCase()

        resultForTitle = titleInLowerCase.includes(searchQueryInLowerCase)
        resultForKeywords = article.keywords.includes(searchQueryInLowerCase)
        result = resultForTitle || resultForKeywords
      }
      if (context.filters.category)
        result = result && context.filters.category === article.categoryId
      if (context.filters.year)
        result =
          result && context.filters.year.toString() === article.date.slice(0, 4)

      return result
    })
    setArticles(filteredArticles)
  }

  useEffect(async () => {
    await getCategories()
    filter()
  }, [context.filters])

  return (
    <>
      <Navbar setArticles={setArticles} articlesCopy={articlesCopy} />
      <div style={{ width: '75%', margin: '0 auto' }}>
        <Box display="flex" flexDirection="row" justifyContent="center" p={1}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={context.filters.category}
              onChange={handleCategoryChange}
              color="secondary"
            >
              <MenuItem value={null}>All</MenuItem>
              {categories &&
                categories.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={context.filters.year}
              onChange={handleYearChange}
              color="secondary"
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </FormControl>
          <SubmitButton
            href={localStorage.getItem('token') ? '/article-upload' : '/login'}
          >
            Submit new article
          </SubmitButton>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center">
          <ArticleList
            articles={articles}
            setArticles={setArticles}
            setArticlesCopy={setArticlesCopy}
          />
        </Box>
      </div>
      <Footer />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectColor: {
    color: '#55bbf1',
  },
}))

const SubmitButton = styled.a`
  width: 180px;
  height: 36px;
  background: grey;
  border: 2px solid grey;
  border-radius: 10px;
  color: white;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  transition: all 0.1s ease-in;
  position: absolute;
  right: 0;
  margin-top: 1em;
  margin-right: 1em;

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 667px) {
    position: relative;
    margin-left: 2em;
    padding-left: 5px;
    padding-right: 5px;
  }
`

export default ArticlesPage
