/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import AuthService from '../services/AuthService'
import Navbar from '../components/Navbar'
import Validator from '../helpers/Validations'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginPage = () => {
  const classes = useStyles()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState({})

  const handleEmailChange = (event) => {
    if (!Validator.emailValidate(event.target.value)) {
      setError({
        ...error,
        email: 'Please enter a valid email address!',
      })
    } else {
      setError({
        ...error,
        email: '',
      })
    }
    setEmail(event.target.value)
  }
  const handlePasswordChange = (event) => {
    if (!Validator.passwordValidate(event.target.value)) {
      setError({
        ...error,
        password: 'Password must be between 6 and 50 characters!',
      })
    } else {
      setError({
        ...error,
        password: '',
      })
    }
    setPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    AuthService.login({
      email,
      password,
    })
      .then(() => {
        history.push('/')
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (localStorage.getItem('token')) history.push('/')
  }, [])

  return (
    <>
      <Navbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <form className={classes.form}>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              autoFocus
              error={error.email}
              helperText={error.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="current-password"
              error={error.password}
              helperText={error.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={handleSubmit}
              disabled={error.email || error.password}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item>
                <Link
                  href="/register"
                  variant="body2"
                  style={{ color: '#2B2B2B' }}
                >
                  Don't have an account? Sign Up
                </Link>
                <Link
                  href="/password-form"
                  variant="body2"
                  style={{ color: '#2B2B2B' }}
                >
                  <br></br> Forgot password?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  )
}

export default LoginPage
