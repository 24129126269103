import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LinearProgress } from '@material-ui/core'
import AuthService from '../services/AuthService'

const PrivateRoute = ({ children, ...rest }) => {
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    AuthService.verify(localStorage.getItem('token')).then((res) => {
      setVerified(res)
      setLoading(false)
    })
  }, [])

  return loading ? (
    <LinearProgress />
  ) : (
    <Route
      {...rest}
      render={() => (verified ? children : <Redirect to="/login" />)}
    />
  )
}

export default PrivateRoute
