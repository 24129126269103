import React from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import SamirImage from '../assets/images/editors/samir-pandya.jpg'
import EduardoImage from '../assets/images/editors/eduardo-perez.jpeg'
import MatthewImage from '../assets/images/editors/matthew-clifton.jpg'
const EditorsData = [
  {
    name: 'Samir Pandya',
    title: 'MD, FACS FAAP - Dallas, USA',
    img: SamirImage,
  },
  {
    name: 'Eduardo Perez',
    title: 'MD, FACS FAAP - Miami, USA',
    img: EduardoImage,
  },
  {
    name: 'Matthew Clifton',
    title: 'MD, FACS FAAP - Atlanta, USA',
    img: MatthewImage,
  },
]

const ChiefEditors = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Typography
        gutterBottom
        variant="h6"
        component="h2"
        className={classes.heading}
      >
        Co-Editors in Chief
      </Typography>
      <Grid item xs={12} style={{ padding: '20px' }}>
        <Grid container justify="center" spacing={3}>
          {EditorsData &&
            EditorsData.map((data) => (
              <Grid key={data} item>
                <Paper className={classes.paper}>
                  <img src={data.img} style={{ maxWidth: '100%' }} alt="" />
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h2"
                    className={classes.typography}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.typography}
                  >
                    {data.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    margin: '50px 0',
    overflow: 'hidden',
  },
  paper: {
    height: '100%',
    width: '300px',
  },
  typography: {
    marginLeft: '10px',
    marginTop: '5px',
    marginBottom: '10px',
  },
  heading: {
    marginLeft: '10%',
    color: 'rgb(85,187,241)',
    fontSize: '2.35rem',
  },
  control: {
    padding: theme.spacing(2),
  },
}))

export default ChiefEditors
