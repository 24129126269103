import React from 'react'
import styled from 'styled-components'
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

const useStyles = makeStyles(() => ({
  mainAccordion: {
    marginTop: '5px',
    marginBottom: '5px',
  },
  nestedAccordion: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  accordionDetailsFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginBottom: {
    marginBottom: '45px',
  },
}))

const TermsPage = () => {
  const classes = useStyles()

  return (
    <Wrapper>
      <Navbar />
      <Container maxWidth="lg">
        <Row>
          <Typography component="h3" variant="h3" style={{ fontWeight: '800' }}>
            Terms and Conditions
          </Typography>
        </Row>
        <Row>
          <Typography component="p" style={p1}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec
            leo dui. Nullam tincidunt eu purus vitae vulputate. Aliquam placerat
            ex auctor, feugiat sapien at, facilisis ex. Sed libero erat, maximus
            id purus luctus, tincidunt tincidunt ex. Praesent molestie dolor ut
            libero efficitur congue. Praesent sagittis non lacus sed posuere.
            Aenean efficitur risus sit amet enim pharetra, ut aliquet nunc
            pharetra. Ut vitae vestibulum erat. Praesent tristique pellentesque
            elit ut interdum. Aliquam imperdiet elit purus, ut lobortis purus
            blandit non. Proin dapibus faucibus sapien. Morbi lobortis lacinia
            malesuada. Sed luctus at erat et pharetra. Praesent interdum dui nec
            libero fermentum, eu scelerisque lorem maximus. Nulla id convallis
            est.
          </Typography>
        </Row>

        <Row>
          <Typography component="h3" variant="h3" style={{ fontWeight: '800' }}>
            Use of English Language
          </Typography>
        </Row>
        <Row>
          <Typography component="p">
            All submissions must be in English. Appropriate use of English is a
            requirement for review and publication in <b>JTIPs</b>. For
            non-native English speakers, we encourage you to use widely
            available translation services. Please note that employing the use
            of such a service is not mandatory and using it, or any other
            language editing service does not guarantee the acceptance of any
            paper. All submissions are subject to peer review.
          </Typography>
        </Row>

        <Accordion className={classes.mainAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              component="h4"
              variant="h4"
              style={{ fontWeight: '800' }}
            >
              Subsection
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="p">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec
              leo dui. Nullam tincidunt eu purus vitae vulputate. Aliquam
              placerat ex auctor, feugiat sapien at, facilisis ex. Sed libero
              erat, maximus id purus luctus, tincidunt tincidunt ex. Praesent
              molestie dolor ut libero efficitur congue. Praesent sagittis non
              lacus sed posuere. Aenean efficitur risus sit amet enim pharetra,
              ut aliquet nunc pharetra. Ut vitae vestibulum erat. Praesent
              tristique pellentesque elit ut interdum. Aliquam imperdiet elit
              purus, ut lobortis purus blandit non. Proin dapibus faucibus
              sapien. Morbi lobortis lacinia malesuada. Sed luctus at erat et
              pharetra. Praesent interdum dui nec libero fermentum, eu
              scelerisque lorem maximus. Nulla id convallis est.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
      <Footer />
    </Wrapper>
  )
}

export default TermsPage

const Wrapper = styled.div``

const Row = styled.div`
  display: flex;
  margin: 30px 0;
  justify-content: space-between;
  align-items: center;
`
const p1 = {
  width: '70%',
}
