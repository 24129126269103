import { NotificationManager } from 'react-notifications'

class ArticleService {
  static BACKEND_URL = process.env.REACT_APP_BACKEND_URL

  static async getArticles(articlelimit) {
    return fetch(`${this.BACKEND_URL}/api/articles?limit=${articlelimit}`).then(
      (response) => response.json(),
    )
  }

  static async getArticle(id) {
    return fetch(`${this.BACKEND_URL}/api/articles/${id}`).then((response) =>
      response.json(),
    )
  }

  static async getAllUserArticles(id) {
    return fetch(`${this.BACKEND_URL}/api/articles/user/${id}`).then(
      (response) => response.json(),
    )
  }

  static async getCategory(id) {
    return fetch(`${this.BACKEND_URL}/api/categories/${id}`).then((response) =>
      response.json(),
    )
  }

  static async addArticle(data) {
    return fetch(`${this.BACKEND_URL}/api/articles`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json()
      if (response.status === 201) {
        NotificationManager.success('Article Added Successfully')
      } else if (response.status === 403) {
        NotificationManager.error('Please authorize!')
      } else if (response.status === 401) {
        try {
          localStorage.removeItem('token')
        } catch (error) {
          NotificationManager.error(error)
        }
      }
    })
  }

  static async editArticle(data) {
    return fetch(`${this.BACKEND_URL}/api/articles/${data.id}`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      response.json()
      if (response.status === 401) {
        try {
          localStorage.removeItem('token')
        } catch (error) {
          NotificationManager.error(error)
        }
      }
    })
  }

  static async getApproved() {
    return fetch(`${this.BACKEND_URL}/api/articles`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((res) => res.json())
  }

  static async getUnapproved() {
    return fetch(`${this.BACKEND_URL}/api/articles/unapproved`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((res) => res.json())
  }

  static async getVideo(id) {
    return fetch(`${this.BACKEND_URL}/api/articles/${id}`, {
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((res) => res.json())
  }

  static async approveArticle(data) {
    return fetch(`${this.BACKEND_URL}/api/articles/${data.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem('token'),
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.ok) {
        NotificationManager.success('  Decision made!')
        res.json()
      } else if (res.status === 401) {
        localStorage.removeItem('token')
      }
    })
  }

  static async deleteArticle(data) {
    return fetch(`${this.BACKEND_URL}/api/articles/${data}`, {
      method: 'DELETE',
      headers: {
        'x-auth-token': localStorage.getItem('token'),
      },
    }).then((response) => {
      response.json()
      if (response.status === 200) {
        NotificationManager.success('Article Deleted Successfully')
      } else if (response.status === 403) {
        NotificationManager.error('Please authorize!')
      } else if (response.status === 401) {
        try {
          localStorage.removeItem('token')
        } catch (error) {
          NotificationManager.error(error)
        }
      }
    })
  }

  static async deleteVideo(data) {
    return fetch(`https://api.vimeo.com/videos/${data}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/vnd.vimeo.*+json;version=3.4',
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_TOKEN}`,
      },
    }).then((response) => {
      response.json()
      if (response.status === 401) {
        try {
          localStorage.removeItem('token')
        } catch (error) {
          NotificationManager.error(error)
        }
      }
    })
  }
}

export default ArticleService
