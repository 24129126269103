/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import { useHistory, useLocation } from 'react-router-dom'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import UserService from '../services/UserService'

// this component reset password by user id. on this page you can get on click button in email.

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const ResetPassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [password, setPassword] = useState('')
  const [tokenExpired, setTokenExpired] = useState(false)
  const [passwordtwo, setPasswordtwo] = useState('')

  useEffect(async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/invite/password/${
        location.pathname.split('/')[2] / process.env.REACT_APP_ID_SECRET
      }`,
    ).then((res) => {
      res.json().then((data) => {
        if (data.message !== 'ok') setTokenExpired(true)
      })
    })
  }, [])

  const handlePasswordChange = (event) => setPassword(event.target.value)
  const handlePasswordTwoChange = (event) => setPasswordtwo(event.target.value)

  // this function reset password for email by id

  const handleSubmit = (event) => {
    const id = location.pathname.split('/')[2] / process.env.REACT_APP_ID_SECRET

    event.preventDefault()

    if (password === passwordtwo) {
      UserService.editUserPassword(
        {
          password,
        },
        id,
      )
        .then(async () => {
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/invite/password/${id}`,
            {
              method: 'DELETE',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
              },
            },
          ).then(() => {
            history.push('/')
          })
        })
        .catch(() => {})
    }
  }

  const handleDeleteInvite = async (event) => {
    const id = location.pathname.split('/')[2] / process.env.REACT_APP_ID_SECRET

    event.preventDefault()

    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/invite/password/${id}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(() => {
      history.push('/password-form')
    })
  }

  return (
    <>
      {tokenExpired ? (
        <div>
          <h1>Your token is expired</h1>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={handleDeleteInvite}
          >
            Reset password again!
          </Button>
        </div>
      ) : (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset password
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                color="secondary"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={password}
                onChange={handlePasswordChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                color="secondary"
                required
                fullWidth
                name="passwordtwo"
                label="Password"
                type="password"
                id="passwordtwo"
                value={passwordtwo}
                onChange={handlePasswordTwoChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Reset password
              </Button>
            </form>
          </div>
        </Container>
      )}
    </>
  )
}

export default ResetPassword
