import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import GA4React from 'ga-4-react'
import './index.css'
import { createTheme, ThemeProvider } from '@material-ui/core'
import App from './App'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#55bbf1',
    },
    error: {
      main: '#FF0000',
    },
  },
})

const ga4react = new GA4React('G-6YN9M07D9H')
;(async () => {
  await ga4react.initialize()

  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()
