/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router-dom'
import UserService from '../services/UserService'
import AdminPanelContext from '../context/AdminPanelContext'
import Validator from '../helpers/Validations'

const AddEditUser = () => {
  const context = React.useContext(AdminPanelContext)
  const [userID] = useState(context.userID)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [error, setError] = useState({})
  const [role, setRole] = useState()
  const classes = useStyles()
  const history = useHistory()

  const handleFirstNameChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        firstName: 'First name is required!',
      })
    } else if (!Validator.max50CharsValidate(event.target.value)) {
      setError({
        ...error,
        firstName: 'Maximum number of characters allowed is 50!',
      })
    } else {
      setError({
        ...error,
        firstName: '',
      })
    }

    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event) => {
    if (!Validator.required(event.target.value)) {
      setError({
        ...error,
        lastName: 'Last name is required!',
      })
    } else if (!Validator.max50CharsValidate(event.target.value)) {
      setError({
        ...error,
        lastName: 'Maximum number of characters allowed is 50!',
      })
    } else {
      setError({
        ...error,
        lastName: '',
      })
    }
    setLastName(event.target.value)
  }

  const handleEmailChange = (event) => {
    if (!Validator.emailValidate(event.target.value)) {
      setError({
        ...error,
        email: 'Please enter a valid email address!',
      })
    } else {
      setError({
        ...error,
        email: '',
      })
    }
    setEmail(event.target.value)
  }

  const handleRoleChange = (event) => {
    setRole(event.target.checked)
  }

  const handlePasswordChange = (event) => {
    if (!Validator.passwordValidate(event.target.value)) {
      setError({
        ...error,
        password: 'Password must be between 6 and 50 characters!',
      })
    } else {
      setError({
        ...error,
        password: '',
      })
    }
    setPassword(event.target.value)
  }

  const handleConfirmedPasswordChange = (event) => {
    if (!Validator.passwordValidate(event.target.value)) {
      setError({
        ...error,
        confirmedPassword: 'Password must be between 6 and 50 characters!',
      })
    } else if (event.target.value !== password) {
      setError({
        ...error,
        confirmedPassword: "Password's must match!",
      })
    } else {
      setError({
        ...error,
        confirmedPassword: '',
      })
    }
    setConfirmedPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (context.actionEdit === 0) {
      UserService.addUser({
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        role: role ? 1 : 0,
      })
        .then(() => {
          if (localStorage.getItem('token') === null) {
            history.push('/login')
          } else {
            history.push('/admin/users')
          }
        })
        .catch(() => {})
    } else if (role === 2) {
      UserService.editUser({
        id: userID,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      })
        .then(() => {
          if (localStorage.getItem('token') === null) {
            history.push('/login')
          } else {
            history.push('/admin/users')
          }
        })
        .catch(() => {})
    } else if (password !== '') {
      UserService.editUser({
        id: userID,
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        role: role ? 1 : 0,
      })
        .then(() => {
          if (localStorage.getItem('token') === null) {
            history.push('/login')
          } else {
            history.push('/admin/users')
          }
        })
        .catch(() => {})
    } else {
      UserService.editUser({
        id: userID,
        first_name: firstName,
        last_name: lastName,
        email,
        role: role ? 1 : 0,
      })
        .then(() => {
          if (localStorage.getItem('token') === null) {
            history.push('/login')
          } else {
            history.push('/admin/users')
          }
        })
        .catch(() => {})
    }
  }

  useEffect(async () => {
    if (userID !== null) {
      const user = await UserService.getSingleUser(userID)
      setFirstName(user.first_name)
      setLastName(user.last_name)
      setEmail(user.email)
      setRole(user.role)
    }
  }, [userID])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Button
        onClick={() => {
          context.setUserID(null)
          history.goBack()
        }}
      >
        <ArrowBackIosIcon />
        Back
      </Button>
      <form className={classes.form} noValidate>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={firstName}
              onChange={handleFirstNameChange}
              autoFocus
              error={error.firstName}
              helperText={error.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={handleLastNameChange}
              error={error.lastName}
              helperText={error.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              onChange={handleEmailChange}
              autoComplete="email"
              error={error.email}
              helperText={error.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              type="password"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="password"
              error={error.password}
              helperText={error.password}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              type="password"
              margin="normal"
              color="secondary"
              required
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              autoComplete="password"
              value={confirmedPassword}
              onChange={handleConfirmedPasswordChange}
              error={error.confirmedPassword}
              helperText={error.confirmedPassword}
            />
          </Grid>
          {userID !== 1 && (
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedC"
                  checked={role}
                  onChange={handleRoleChange}
                />
              }
              label="Admin"
            />
          )}

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={handleSubmit}
            disabled={
              error.email ||
              error.password ||
              error.confirmedPassword ||
              error.firstName ||
              error.lastName ||
              firstName === '' ||
              lastName === '' ||
              email === ''
            }
          >
            {context.actionEdit === 0 ? 'ADD USER' : 'EDIT USER'}
          </Button>
        </Grid>
      </form>
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default AddEditUser
