import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'
import ArticleCard from './ArticleCard'
import ArticleService from '../services/ArticleService'

const LatestVideos = () => {
  const [articles, setArticles] = useState([])

  useEffect(async () => {
    const data = await ArticleService.getArticles()
    setArticles(data)
  }, [])

  return (
    <Container>
      <Box p={3}>
        <Typography variant="h4" style={{ fontWeight: 700 }}>
          Latest Videos
        </Typography>
        <Box mb={3} />
        <Grid container spacing={2} justify="center">
          {articles &&
            articles.map((article) => (
              <Grid key={article.id} item>
                <ArticleCard article={article} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Container>
  )
}

export default LatestVideos
