import { Paper, Typography, Button, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import CommentService from '../services/CommentService'
import CommentCard from './Comment-Card'
import 'react-notifications/lib/notifications.css'

const Comments = ({ article, customStyle }) => {
  const history = useHistory()
  const [comments, setComments] = useState([])
  const [text, setText] = useState('')

  const handleTextChange = (event) => {
    setText(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    addComment()
    setText('')
  }

  const getComments = async () => {
    if (article) {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comments/${article.id}`)
        .then((response) => response.json())
        .then((data) => {
          setComments(data)
        })
    }
  }

  const addComment = async () => {
    const decodedID = jwtDecode(localStorage.getItem('token'))

    const data = {
      comment: text,
      userId: decodedID.user.id,
      articleId: article.id,
    }

    CommentService.addComment(data).then(() => {
      if (localStorage.getItem('token') === null) {
        history.push('/login')
      }
      getComments()
    })
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
        padding: '10px',
      },
    },
  }))
  const classes = useStyles()

  useEffect(() => {
    getComments()
  }, [])

  function renderComments() {
    return comments.map((comment) => (
      <CommentCard
        key={comment.comment}
        comment={comment}
        getComments={getComments}
      />
    ))
  }

  function AddCommentCard() {
    return (
      <div className={classes.root}>
        <Paper elevation={1}>
          <Typography
            variant="h6"
            component="h2"
            style={{ paddingLeft: '40px', paddingTop: '10px' }}
          >
            Leave a comment
          </Typography>
          <CommentForm
            noValidate
            autoComplete="off"
            style={{
              paddingLeft: '40px',
              paddingRight: '40px',
              paddingBottom: '10px',
            }}
          >
            <TextField
              id="standard-multiline-flexible"
              label="Add a comment"
              rowsMax={6}
              multiline
              value={text}
              onChange={handleTextChange}
              style={{ width: '100%' }}
              color="secondary"
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              style={{ width: '25%', marginTop: '10px' }}
            >
              Submit
            </Button>
          </CommentForm>
        </Paper>
      </div>
    )
  }

  return (
    <div style={{ ...customStyle }}>
      <h1>Comments:</h1>
      <div>{renderComments()}</div>
      {localStorage.getItem('token') ? (
        AddCommentCard()
      ) : (
        <h3>
          Please <Link to="/login">Log In</Link> To Leave a Comment...
        </h3>
      )}
    </div>
  )
}

const CommentForm = styled.form`
  display: flex;
  flex-direction: column;
`

export default Comments
